
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/UserController";
import { roleListState } from "../roles/state";

export const userListState = atom({
    key: 'userListState',
    default: [],
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filterConfigDefault = {
    filter: false,
}

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const userDataStructureState = selector({
    key: 'userDataStructureState',
    get: ({ get }) => (
        [{ 
            label: "Name",
            type: "text",
            order: { table: 1, form: 1 },
            create: [{ enabled: true, size: 4 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },{ 
            label: "Email",
            type: "email",
            order: { table: 2, form: 2 },
            create: [{ enabled: true, size: 4 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          }
          // ,{ 
          //   label: "RCNO",
          //   type: "text",
          //   order: { table: 3, form: 3 },
          //   create: [{ enabled: true, size: 4 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          // }
          ,{ 
            label: "Password",
            type: "text",
            order: { form: 4 },
            create: [{ enabled: true, size: 4, default: 'welcome@123' }],
          },{
            label: "Roles",
            subFields: { label: "name", value: "id" },
            type: "multiselect",
            order: { form: 5 },
            create: [{ enabled: true, size: 8 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: get(roleListState),
          },{ 
            label: "PostPaid",
            type: "switch",
            field: "postpaid",
            order: { table: 6, form: 6 },
            create: [{ enabled: true, size: 2 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          },{ 
            label: "Renewal Amount",
            type: "text",
            order: { table: 7, form: 7 },
            create: [{ enabled: true, size: 3, default:0 }], update: [{ enabled: true, size: 3, default:0 }], view: [ 'same_as:create.0' ],
          },{
            label: "Active",
            type: "switch",
            order: { form: 8 },
            create: [{ enabled: true, size: 3 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
          }]
    )
})