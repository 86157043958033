import { Menu } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, DropdownItem } from "reactstrap"
import { HasPermission } from "../helpers/HasPermission"


export const ActionButtons = ({actions, type, row}) => {

    const [menuVisible, setMenuVisible] = useState(false)
    const [menuPosition, setMenuPosition] = useState({top:0, left:0})

    return (
        <React.Fragment>
            {type === "buttons" ? actions.map((action,key)=>(
                (!action.permission && !action.absolutePermission) || (HasPermission(action.permission)) ? (
                    <React.Fragment key={key}>
                        <Button title={action.label} color={action.color} className='btn-sm waves-effect waves-light' onClick={()=>action.params instanceof Array ? action.fn(row, ...action.params) : action.fn(row)}>
                            <i className={action.icon+" d-block font-size-12"}></i>
                        </Button>&nbsp;
                    </React.Fragment>
                ) : null
            )) : (
                <React.Fragment>
                    <Button color='secondary' className='btn-sm waves-effect waves-light' onClick={(e)=>{ const el = e.target.getBoundingClientRect();setMenuPosition({top: el.top+30, left:el.left});setMenuVisible(!menuVisible)} }>
                        Actions{" "}<i className="mdi mdi-chevron-down"></i>
                    </Button>
                    <Menu disableAutoFocusItem anchorReference="anchorPosition" anchorPosition={menuPosition} keepMounted open={Boolean(menuVisible)} onClose={()=>setMenuVisible(false)}>
                        {actions.map((action,key)=>(
                            <DropdownItem toggle={false} onClick={()=>{setMenuVisible(false);action.params instanceof Array ? action.fn(row, ...action.params) : action.fn(row)}} key={key}><span className={action.icon+" d-block font-size-14"}>{" "}{action.label}</span></DropdownItem>
                            // MenuItem from material-ui/core (without toggle attribute) instead of DropdownItem takes more height per item, but hover highlight is better
                            ))}
                    </Menu>
                </React.Fragment>
            )}
        </React.Fragment>
    )

}