import configData from "../../config.json";
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Modal, Row, Button, Label, Form, FormGroup, Input, Card, NavItem, NavLink, Progress, TabContent, TabPane, PopoverHeader, PopoverBody, } from 'reactstrap';
import { useSetRecoilState } from 'recoil';
import { lastAPIActionTimeState } from '../../state/GlobalState';
import { handleResponse } from '../helpers/HandleResponse';
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";
import Switch from "react-switch";
import InputMask from "react-input-mask";
import Flatpickr from "react-flatpickr"
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "flatpickr/dist/themes/material_blue.css"
import { cloneDeep, get, merge } from 'lodash';
import { stateFromHTML } from 'draft-js-import-html'
import { EditorState } from 'draft-js'
import Dropzone from 'react-dropzone';
import { Link, Popover } from '@material-ui/core';
import classnames from 'classnames';
import notify from '../helpers/Notify';

export const DynamicModal = ({config, toggleModal, ds, submitModes}) => {

    const setLastAPIActionTime = useSetRecoilState(lastAPIActionTimeState)

    // Get Name to Post with (example: Test Name = TestName or Custom Name if provided)
    const getPN = (val) => {
      return val.name || val.label.replace(/ /g, '')
    }
    
    // Get Name of Field (example: Test Name = test_name or Custom Name if provided)
    const getFN = (val) => {
      return val.field || val.label.replace(/ /g, '_').toLowerCase()
    }
    
    // Get Nested Elements, within objects like formData, currentFiles, etc... , param1: obj to get data from, param2: input field to refer to
    const getNE = (oe, input) => {
      try{
        return input.repName ? oe[input.repName][input.repKey] ? oe[input.repName][input.repKey][getPN(input)] : null : oe[getPN(input)]
      } catch(e){
        return null;
      }
    }

    // get Updated Parent Element (updates nested element with value and returns parent element)
    const getUPE = (oe, input, value) => {
      if(input.repName){
        return {...oe, [input.repName]: oe[input.repName].map((t,k)=>(k === input.repKey ? {...t, [getPN(input)] : value} : t)) }
      }
      return {...oe, [getPN(input)]: value}
    }

    
    const getMTU = useMemo(() => (val) => {
      let temp = val?.[config.type?.toLowerCase()]?.[config.typeKey]
      if(temp instanceof Object){
        return temp
      } else if(temp && temp.startsWith("same_as:")) {
        temp = temp.substr(8).split(".")
        return val[temp[0]][temp[1]]
      }
      return false
    }, [config.type, config.typeKey]);

    // Returns the default type of inputs
    const getDefaultDataTypes = (input) => {
      return input.type === "multiselect" || input.type === "dropzone" || input.type === "repeater" ? [] : (input.type === "checkbox" || input.type === "switch" ? false : (input.type === "file" ? null : ''))
    }

    const [isLoading, setIsLoading] = useState({})
    const [formData, setFormData] = useState({})
    const [draftEditors, setDraftEditors] = useState({})
    const [currentFiles, setCurrentFiles] = useState({})
    const [selects, setSelects] = useState({})
    const [selectOptions, setSelectOptions] = useState({})
    const [attributes, setAttributes] = useState({})
    const [wizardConfig, setWizardConfig] = useState({})
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [formLoading, setFormLoading] = useState(true)
    const [confirmDialog, setConfirmDialog] = useState({ id: null, input: null, key: null, top: 0, left: 0, visible: false })
    
    const parseAttributes = useMemo(() => (fd, ld) => {
      // pass data structure as first element (In recursive or repeater mode, it will be children), pass form data as second element (for recursion), loading state is passed as 3rd (for recursion)
      const getAttributes = (fs, fd, ld) => {
        let tempAttributes = {}
        fs.filter((val)=> getMTU(val)?.enabled).forEach((val) => {
          let currentValidations = {}
          if(val.type === "repeater" && val.children.length > 0){
            currentValidations = fd[getPN(val)].map((el,k) => ({...getAttributes(val.children, el, ld[getPN(val)][k])}))
          } else {            
            if(getNE(ld, val) || false){
              currentValidations = {...currentValidations, isDisabled: true, disabled: true }
            } 
            if(getMTU(val).validations){
              let validations = getMTU(val).validations.split("|")
              validations.forEach((validation) => {
                if(validation.includes("_if")){
                  // hidden_if:LinkType,!AccountVerification
                  const params = validation.split(":")[1]?.split(",")
                  validation = validation.split(":")[0].replace("_if","")
                  let conditionMet = false
                  for(var i=1; i< params.length; i++){
                    const not = params[i].substr(0,1) === '!' ? true : false;
                    params[i] = not ? params[i].substr(1) : params[i]
                    const check = params[i] === 'null' ? '' : ((params[i] === '0' || params[i] === 0) ? false : (params[i] === '1' || params[i] === 1) ? true : params[i])
                    const fdValue = fd[params[0]] === null ? '' : ((fd[params[0]] === '0' || fd[params[0]] === 0) ? false : (fd[params[0]] === '1' || fd[params[0]] === 1) ? true : fd[params[0]])
                    conditionMet = (!not && (fdValue === check || parseInt(fdValue) === parseInt(check))) || (not && (fdValue !== check && parseInt(fdValue) !== parseInt(check)))
                    if(conditionMet){
                      break;
                    }
                  }
                  
                  if(conditionMet){
                    validation = validation === "readonly" ? "readOnly" : validation
                    currentValidations = {...currentValidations, [validation]: true}
                    if(validation === "disabled" && (val.type === "select" || val.type === "multiselect")){
                      currentValidations = {...currentValidations, isDisabled: true}
                    }
                  }
                } else {
                  validation = validation === "readonly" ? "readOnly" : validation
                  currentValidations = {...currentValidations, [validation]: true}
                  if(validation === "disabled" && (val.type === "select" || val.type === "multiselect")){
                    currentValidations = {...currentValidations, isDisabled: true}
                  }
                }
              })
            }
          }
          tempAttributes = {...tempAttributes, [getPN(val)]: currentValidations}
        })
        return tempAttributes
      }
      return getAttributes(ds, fd, ld)
      // eslint-disable-next-line
    }, [ds, getMTU]);

    const getStateData = useMemo(() => async(columns=ds, data=config.data, updateOrView=config.type==="Update" || config.type==="View", wizard=config.wizard) => {
      const getFormData = (cols, data, updateOrView) => {
        let tempData = updateOrView ? { id: data.id } : {}
        cols.filter((col)=> getMTU(col)?.enabled).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getFormData(col.children, ir, updateOrView))) : [{...getFormData(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else {
            if(updateOrView){
              if(col.type === "select" || col.type === "multiselect"){
                let temp = get(data, getFN(col));
                temp = temp instanceof Array ? temp.map((val)=>({ label: get(val, col.subFields?.label || "name"), value: get(val, col.subFields?.value || "id") })) : get(temp, col.subFields?.label || "name") ? { label: get(temp, col.subFields?.label || "name"), value: get(temp, col.subFields?.value || "id") } : col.rootValue ? temp : ''
                def = temp instanceof Array ? temp.map((val)=>(val.value)) : col.rootValue ? temp : temp.value
              } else if(col.type === "switch"){
                def = parseInt(get(data, getFN(col))) === 1
              } else if(col.type === "file" || col.type === "dropzone"){
                def = getDefaultDataTypes(col)
              } else if(col.type === "radio"){
                def = get(data, getFN(col)) ? get(data, getFN(col))+"" : ""
              } else {
                def = get(data, getFN(col)) || ""
              }
            } else {
              def = getMTU(col).default || getDefaultDataTypes(col)
            }
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getIsLoading = (cols, data, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getIsLoading(col.children, ir, updateOrView))) : [{...getIsLoading(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else {
            def = false
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getDraftEditors = (cols, data, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled && (col.type === "wysiwyg" || (col.type === "repeater" && col.children.filter((c)=>(c.type === 'wysiwyg')).length > 0))).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getDraftEditors(col.children, ir, updateOrView))) :[{...getDraftEditors(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else {
            def = updateOrView ? EditorState.createWithContent(stateFromHTML(get(data, getFN(col)))) : EditorState.createEmpty()
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getCurrentFiles = (cols, data, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled && (col.type === "file" || col.type === "dropzone" || (col.type === "repeater" && col.children.filter((c)=>(c.type === 'file' || c.type === 'dropzone')).length > 0))).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getCurrentFiles(col.children, ir, updateOrView))) :[{...getCurrentFiles(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else if(updateOrView){
            def = get(data, getFN(col))
            if(col.type === "dropzone") {
              def = def.map((val)=>({ id: val.id, type: 'Permanent', name: get(val, col.subFields?.name), size: get(val, col.subFields?.size), path: get(val, col.subFields?.path) }))
            }
          } else {
            def = getMTU(col).default || getDefaultDataTypes(col)
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getSelects = (cols, data, to = {}, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled && (col.type === "select" || col.type === "multiselect" || (col.type === "repeater" && col.children.filter((c)=>(c.type === 'select' || c.type === 'multiselect')).length > 0))).forEach((col) => {
          let def = '';
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir,k)=>(getSelects(col.children, ir, to[getPN(col)][k], updateOrView))) : [{...getSelects(col.children, {}, to[getPN(col)][0], get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else {
            if(updateOrView){
              let temp = get(data, getFN(col));
              def = temp instanceof Array ? temp.map((val)=>({ label: get(val, col.subFields?.label || "name"), value: get(val, col.subFields?.value || "id") })) : get(temp, col.subFields?.label || "name") ? { label: get(temp, col.subFields?.label || "name"), value: get(temp, col.subFields?.value || "id") } : col.rootValue ? { label: temp, value: temp } : ''
            } else {
              let temp = getMTU(col).default
              def = temp instanceof Array ? to[getPN(col)].filter((o)=>(temp.includes(o.value))) : to[getPN(col)].filter((o)=>(o.value === temp || parseInt(o.value) === parseInt(temp)))[0] || null
              def = def || getDefaultDataTypes(col)
            }
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getStaticSelectOptions = (cols, data, updateOrView) => {
        let tempData = {}
        cols.filter((col)=> getMTU(col)?.enabled && (col.type === "select" || col.type === "multiselect" || (col.type === "repeater" && col.children.filter((c)=>(c.type === 'select' || c.type === 'multiselect')).length > 0))).forEach((col) => {
          let def = ''
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? get(data, getFN(col)).map((ir)=>(getStaticSelectOptions(col.children, ir, updateOrView))) :[{...getStaticSelectOptions(col.children, {}, get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}]
          } else {
            def = col.list instanceof Array ? col.list : []
          }
          tempData = {...tempData, [getPN(col)]: def}
        })
        return tempData
      }
      const getDynamicSelectOptions = async(cols, data, td = {}, ts = {}, updateOrView) => {
        let tempData = cloneDeep(td)
        await Promise.all(cols.filter((col)=> (getMTU(col)?.enabled) && ((col.type === "select" && col.child) || (col.type === "repeater" && col.children.filter((c) => (getMTU(c)?.enabled && c.type === 'select' && c.child)).length > 0))).map(async(col) => {
          let def = ''
          if(col.type === "repeater"){
            def = updateOrView && get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? merge(tempData[getPN(col)], await Promise.all(get(data, getFN(col)).map(async(ir,k)=>(await getDynamicSelectOptions(col.children, ir, {}, ts[getPN(col)][k], updateOrView))))) : merge(tempData[getPN(col)], [{...await getDynamicSelectOptions(col.children, {}, {}, ts[getPN(col)][0], get(data, getFN(col)) && get(data, getFN(col)).length > 0 ? updateOrView : 0)}])
            tempData = {...tempData, [getPN(col)]: def}
          } else {
            let temp = null;
            if(updateOrView){
              temp = get(data, getFN(col))
              temp = temp instanceof Array ?  temp.map((val)=>({ label: get(val, col.subFields?.label || "name"), value: get(val, col.subFields?.value || "id") })) : get(temp, col.subFields?.label || "name") ? { label: get(temp, col.subFields?.label || "name"), value: get(temp, col.subFields?.value || "id") } : ''
            } else {
              temp = getNE(ts, col)
            }
            const childName = col.child.name.replace(/ /g, '')
            if(temp){
              const response = await col.child.fn({id: temp.value || temp});
              tempData = (response && response.Status !== 'error') ? ({...tempData, [childName]: response.data?.map((val)=>({ label: val.name, value: val.id }))}) : ({...tempData, [childName]: []}) ;
            } else {
              tempData = {...tempData, [childName]: []}
            }
          }
        }))
        return tempData
      }
      const getWizardConfig = (wizard) => {
        let tabs = []
        if(wizard){
          ds.filter((val)=> getMTU(val)?.enabled).sort((a,b)=>(a.order?.form < b.order?.form ? 1 : -1)).sort((a,b)=>(a.section?.id > b.section?.id ? 1 : -1)).forEach((val) => {
            if(val.section && val.section.label && !tabs.includes(val.section.label)){
              tabs.push(val.section.label)
            }
          })
        }
        if(tabs.length > 0){
          return { activeTab: 1, tabCount: tabs.length, tabs: tabs }
        }
        return {}
      }
      const tempStaticOptions = getStaticSelectOptions(columns, data, updateOrView)
      const tempSelects = getSelects(columns, data, tempStaticOptions, updateOrView)
      return { formData: getFormData(columns, data, updateOrView), draftEditors: getDraftEditors(columns, data, updateOrView), currentFiles: getCurrentFiles(columns, data, updateOrView), isLoading: getIsLoading(columns, data, updateOrView), selects: tempSelects, selectOptions: await getDynamicSelectOptions(columns, data, tempStaticOptions, tempSelects, updateOrView), wizardConfig: getWizardConfig(wizard) }
      // eslint-disable-next-line
    }, [getMTU])

    useEffect(() => {
      if(config.visible){
        const setStates = async() => {
          setFormLoading(true)
          const stateData = await getStateData();
          setWizardConfig(stateData.wizardConfig)
          setDraftEditors(stateData.draftEditors)
          setCurrentFiles(stateData.currentFiles)
          setIsLoading(stateData.isLoading)
          setSelectOptions(stateData.selectOptions)
          setSelects(stateData.selects)
          setFormData(config.idKey ? {...stateData.formData, [config.idKey]: config.data.id} : stateData.formData)
          setSubmitDisabled(false)
          setFormLoading(false)
        }
        setStates();
      } else {
        setFormData({})
        setDraftEditors({})
        setCurrentFiles({})
        setSelects({})
        setSelectOptions({})
        setIsLoading({})
        setWizardConfig((s) => ({...s, activeTab: 1}))
        setSubmitDisabled(true)
        setFormLoading(true)
      }
    }, [config.visible, config.idKey, config.data.id, getStateData])

    
    useEffect(() => {
      if(!formLoading){
        setAttributes(parseAttributes(formData, isLoading))
      }
    }, [formData, isLoading, formLoading, parseAttributes])

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return "0 Bytes";
      const k = 1024, dm = decimals < 0 ? 0 : decimals, sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const defaultOnChange = (input) => {
      return (e) => setFormData(getUPE(formData, input, e.target.value))
    }

    const getSelectAttr = (input) => {
      let attr = {}, onChange = '', value = ''
      if(input.type === "select"){
        value = getNE(formData, input) && getNE(formData, input) !== "" ? {label: getNE(selects,input).label, value: getNE(selects,input).value} : ""
        onChange = async(e) => { 
          // if child exists, call child function to get data for child on change of this (parent)
          if(input.child){
            const childName = input.child.name.replace(/ /g, '')
            let childInput = {};
            if(input.repName){
              childInput = ds.filter((f)=>(f.label === input.repName.replace(/([A-Z])/g, " $1").substr(1)))[0]?.children?.filter((f)=>(f.label === childName.replace(/([A-Z])/g, " $1").substr(1)))[0];
              childInput = {...childInput, repName: input.repName, repKey: input.repKey}
            } else {
              childInput = ds.filter((f)=>(f.label === childName.replace(/([A-Z])/g, " $1").substr(1)))[0]
            }
            setIsLoading(getUPE(isLoading, childInput, true))
            const response = await input.child.fn({id: e.value});
            if(! (response?.Status === "error")){
              setSelectOptions(getUPE(selectOptions, childInput, response.data?.map((val)=>({ label: val.name, value: val.id }))))
              setIsLoading(getUPE(isLoading, childInput, false))
            } else {
              notify({ status: 'error', message: "Failed to fetch data. Please refresh and try again!" });
              setSelectOptions(getUPE(selectOptions, childInput, [])); 
            }
            const def = getMTU(childInput).default || getDefaultDataTypes(childInput)
            setFormData(getUPE(getUPE(formData, input, e.value), childInput, def))
            setSelects(getUPE(getUPE(selects, input, {label: e.label, value: e.value}), childInput, def))
          } else {
            setFormData(getUPE(formData, input, e.value));
            setSelects(getUPE(selects, input, {label: e.label, value: e.value}))
          }
        }
      } else if(input.type === "multiselect") {
        value = getNE(formData, input) && getNE(formData, input) !== "" ? getNE(selects,input).map((val)=>({label: val.label, value: val.value})) : ""
        onChange = (e) => { 
          setFormData(getUPE(formData, input, e ? e.map((val)=>(val?.value)) : []))
          setSelects(getUPE(selects, input, e ? e.map((val)=>({label: val?.label, value: val?.value})) : []))
        }
        attr = { ...attr, isMulti: true, closeMenuOnSelect: false }
      }
      return {...attr, classNamePrefix: "select2-selection", value: value, options: getNE(selectOptions, input), onChange: onChange}
    }

    const getSwitchAttr = (input) => {
      const checkedIcon = <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100%",fontSize: 12,color: "#fff",paddingRight: 2}}>{" "}{input.list ? input.list[0] || "Yes" : "Yes"}</div>
      const uncheckedIcon = <div style={{display: "flex",justifyContent: "center",alignItems: "center",height: "100%",fontSize: 12,color: "#fff",paddingRight: 2}}>{" "}{input.list ? input.list[1] || "No" : "No"}</div>
      const onChange = () => setFormData(getUPE(formData, input, !getNE(formData, input)))
      const checked = getNE(formData,input) === true;
      return { onColor: "#38a4f8", onChange: onChange, checked: checked, uncheckedIcon: uncheckedIcon, checkedIcon: checkedIcon}
    }

    const getMaskAttr = (input) => {
      const mask = getMTU(input).mask ? getMTU(input).mask : ""
      return { mask: mask, value: getNE(formData, input), onChange: defaultOnChange(input), className: (getMTU(input).classes || '')+" form-control input-color" }
    }

    const getDatepickerAttr = (input) => {
      const options = {...getMTU(input).options}
      let onChange = (_,e) => setFormData(getUPE(formData, input, e))
      if(options.mode === "range"){
        onChange = (_,e) => e.split(" to ").length > 1 ? setFormData(getUPE(formData, input, e.split(" to "))) : false
      } else if(options.mode === "multiple"){
        onChange = (_,e) => setFormData(getUPE(formData, input, e.split(", ")))
      }
      return { value: getNE(formData, input), onChange: onChange, options: options, className: (getMTU(input).classes || '')+" form-control d-block" }
    }

    const getNormalAttr = (input) => {
      return { type: input.type, value: getNE(formData, input), onChange: defaultOnChange(input) }
    }

    const getWysiwygAttr = (input) => {
      const onChange = (e) => setFormData(getUPE(formData, input, draftToHtml(e)))
      const onEditorStateChange = (e) => setDraftEditors(getUPE(draftEditors, input, e))
      return { type: input.type, editorState: getNE(draftEditors, input), onChange: onChange, onEditorStateChange: onEditorStateChange }
    }

    const getAttr = (input) => {
      // return null
      let attr = {...(getMTU(input).attributes || {}), ...(getNE(attributes,input) || {}), className: getMTU(input).classes || ''}
      if(input.type === "select" || input.type === "multiselect"){
        attr = {...attr, ...getSelectAttr(input)}
      } else if(input.type === "switch"){
        attr = {...attr, ...getSwitchAttr(input)}
      } else if(input.type === "mask"){
        attr = {...attr, ...getMaskAttr(input)}
      } else if(input.type === "datepicker"){
        attr = {...attr, ...getDatepickerAttr(input)}
      } else if(input.type === "wysiwyg"){
        attr = {...attr, ...getWysiwygAttr(input)}
      } else {
        attr = {...attr, ...getNormalAttr(input)}
      }
      return attr
    }

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    const uploadDzFile = async(e, input) => {
      let tempFormData = cloneDeep(formData);
      let tempCurrentFiles = cloneDeep(currentFiles);
      await Promise.all(e.map(async(file) => {
        let id = Math.random();
        file.id = id;
        file.base64 = await toBase64(file);
        getNE(tempFormData, input).push(file);
        getNE(tempCurrentFiles, input).push({ id: id, type: 'Temp', name: file.name, size: file.size, path: URL.createObjectURL(file) }) 
      }))
      setFormData(tempFormData);
      setCurrentFiles(tempCurrentFiles);
    }
    
    const removeDzFile = async(input, file, confirmed=false, e) => {
      let tempFiles = [];
      let removed = true;
      await Promise.all(getNE(currentFiles, input).map(async(f) => {
        if(f.id !== file.id){
          tempFiles.push(f)
        } else if(f.id === file.id && f.type === 'Permanent' && input.deleteFn){
          if(!confirmed){
            const el = e.target.getBoundingClientRect();
            const action = { fn: removeDzFile, params: [input,file,true] }
            toggleConfirmDialog(f.id, action, el.top+40, el.left)
            removed = false;
          } else {
            setSubmitDisabled(true)
            const response = await input.deleteFn({ id: f.id });
            handleResponse(response)
            if(! (response?.Status ==='success')){
              removed = false;
            }
            setSubmitDisabled(false)
          }
        }
      }))
      if(removed){
        setCurrentFiles(getUPE(currentFiles, input, tempFiles))
        setFormData(getUPE(formData, input, formData[getPN(input)].filter((f) => (f.id !== file.id))))
      }
    }
    
    const toggleWizardTab = (tab) => {
      if (wizardConfig.activeTab !== tab) {
          if( tab >= 1 && tab <= wizardConfig.tabCount ){
            setWizardConfig((s) => ({...s, activeTab: tab}))
          }
      }
    }
    
    const addRepeaterRow = async(input) => {
      const initialData = await getStateData(input.children, {}, false)
      setIsLoading({...isLoading, [getPN(input)]: [...isLoading[getPN(input)], initialData.isLoading]})
      if(Object.keys(initialData.draftEditors).length > 0){
        setDraftEditors({...draftEditors, [getPN(input)]: [...draftEditors[getPN(input)], initialData.draftEditors]})
      }
      if(Object.keys(initialData.currentFiles).length > 0){
        setCurrentFiles({...currentFiles, [getPN(input)]: [...currentFiles[getPN(input)], initialData.currentFiles]})
      }
      if(Object.keys(initialData.selects).length > 0){
        setSelects({...selects, [getPN(input)]: [...selects[getPN(input)], initialData.selects]})
      }
      if(Object.keys(initialData.selectOptions).length > 0){
        setSelectOptions({...selectOptions, [getPN(input)]: [...selectOptions[getPN(input)], initialData.selectOptions]})
      }
      setFormData({...formData, [getPN(input)]: [...formData[getPN(input)], initialData.formData]})
    }

    const deleteRepeaterRowPre = async(e,input,data,key) => {
      if(input.deleteFn && data.id){
        const el = e.target.getBoundingClientRect()
        const action = { fn: deleteRepeaterRow, params: [input,key,data] }
        toggleConfirmDialog(data.id, action, el.top+40, el.left)
      } else {
        await deleteRepeaterRow(input,key,data)
      }
    }
    
    const deleteRepeaterRow = async(input,key,data) => {
      let removed = true;
      if(input.deleteFn && data.id){
        setSubmitDisabled(true)
        const response = await input.deleteFn({ id: data.id });
        handleResponse(response)
        if(! (response?.Status ==='success')){
          removed = false;
        }
        setSubmitDisabled(false)
      }
      if(removed){
        setIsLoading({...isLoading, [getPN(input)]: isLoading[getPN(input)].filter((_,k)=>(k!==key))})
        if(input.type === "wysiwyg"){
          setDraftEditors({...draftEditors, [getPN(input)]: draftEditors[getPN(input)].filter((_,k)=>(k!==key))})
        } else if(input.type === "file" || input.type === "dropzone"){
          setCurrentFiles({...currentFiles, [getPN(input)]: currentFiles[getPN(input)].filter((_,k)=>(k!==key))})
        } else if(input.type === "select" || input.type === "multiselect"){
          setSelects({...selects, [getPN(input)]: selects[getPN(input)].filter((_,k)=>(k!==key))})
          setSelectOptions({...selectOptions, [getPN(input)]: selectOptions[getPN(input)].filter((_,k)=>(k!==key))})
        }
        setFormData({...formData, [getPN(input)]: formData[getPN(input)].filter((_,k)=>(k!==key))})
      }
    }   

    const submitWizardForm = async(e) => {
      e.preventDefault();
      const tab = wizardConfig?.activeTab
      if (tab === wizardConfig?.tabCount){
        onSubmit(e)
      } else {
        toggleWizardTab(tab + 1);
      }
    }
    const onSubmit = async(event) => {
      event.preventDefault();
      setSubmitDisabled(true)
      // Form Data State is looped through to remove properties from file arrays and make it just a base64 array
      let tempForm = cloneDeep(formData)
      Object.keys(tempForm).forEach((val)=>{
        // hidden, disabled & readonly will not post
        if(attributes[val]?.hidden || attributes[val]?.disabled || attributes[val]?.isDisabled) {
          delete tempForm[val]
        } else if(tempForm[val] instanceof Array && tempForm[val][0] && tempForm[val][0] instanceof File){
          tempForm[val].forEach((f,k) => {
            tempForm[val][k] = f.base64
          })
        } else if(tempForm[val] instanceof Array && tempForm[val][0] instanceof Object){
          let tempObjArr = []
          // Remove Empty Repeater Rows and convert Obj to Arr if only 1 element in repeater
          tempForm[val].forEach((row,k) => {
            let pass = false;
            Object.keys(row).forEach((key) => {
              if((attributes[val][k][key]?.hidden || attributes[val][k][key]?.disabled || attributes[val][k][key]?.isDisabled)){
                delete tempForm[val][k][key]
              } else if((! (row[key] === '' || row[key] === null))){
              // if(! (row[key] === '' || row[key] === null)){
                if(Object.keys(row).length === 1){
                  tempObjArr.push(row[key])
                }
                pass = true;
              }
            })
            if(pass && Object.keys(row).length > 1){
              tempObjArr.push(row)
            }
          })
          tempForm[val] = tempObjArr
        }
      })
      if(config.type === "Update" || config.type === "Create"){
        const response = await submitModes[config.type.toLowerCase()][config.typeKey].action(tempForm)
        handleResponse(response)
        if(response?.Status ==='success') {
          setLastAPIActionTime(new Date())
          if(config.type === "Create") {
              toggleModal();
          }
        }
        setSubmitDisabled(false)
      }
    }

    const toggleConfirmDialog = (id=null, action=null, top=0,left=0) => {
      setConfirmDialog((s)=>({...s, id: id, action: action, top: top, left: left, visible: (id === null ? false : !s.visible)}))
    }

    const renderInputs = (data) => {
      // return data.sort((a,b)=>(a.order?.form < b.order?.form ? 1 : -1)).sort((a,b)=>(a.section?.id > b.section?.id ? 1 : -1)).map((val,key)=>(
      return cloneDeep(data).filter((v)=>(getMTU(v)?.enabled)).sort((a,b)=>(a.order?.form < b.order?.form ? 1 : -1)).sort((a,b)=>(a.section?.id > b.section?.id ? 1 : -1)).map((val,key)=>(
        <React.Fragment key={key}>
          {getMTU(val)?.enabled && (!getNE(attributes,val)?.hidden) && config.type !== "View" ? (
            <React.Fragment>
              {!config.wizard && ((data[key-1] && val.section?.id && val.section?.id !== data[key-1].section?.id) || (!data[key-1] && val.section?.id)) ? (
                <Col xs={12}>{data[key-1] ? <hr /> : null}<h5 className={data[key-1] ? "mt-3 mb-3" : "mb-3"}>{val.section?.label}</h5></Col>
              ) : null}
              <Col key={key} xs={getMTU(val).size || 6} data-order={val.order.form}>
                <FormGroup style={val.type === "checkbox" ? {cursor:'pointer'} : null} onClick={() => val.type === "checkbox" ? setFormData(getUPE(formData, val, !getNE(formData, val))) : false} className={val.type === "select" ? "selec2-container" : ""}>
                  { !(val.repKey && val.repKey > 0) || val.repeatLabel ? (
                   <Label for={val.label}>{val.label} {getMTU(val).subText ? <i style={{fontSize:'0.8em', color:'#999'}}>({getMTU(val).subText})</i> : null}</Label>
                  ) : null}
                  {(val.type === "text" || val.type === "email" || val.type === "password" || val.type === "number" || val.type === "textarea" || val.type === "color" ? (
                    <Input {...getAttr(val)} />
                  ) : null )}
                  {val.type === "select" || val.type === "multiselect" ? (
                    <Select {...getAttr(val)} />
                  ) : null}
                  {val.type === "switch" ? (
                    <><br /><Switch {...getAttr(val)} /></>
                  ) : null}
                  {val.type === "checkbox" ? (
                    <div className="custom-control custom-checkbox" style={{zIndex:0}}>
                      <input type={val.type} className={(getMTU(val).classes || '')+" custom-control-input"} {...(getMTU(val).attributes || {})} {...(getNE(attributes, val) || {})} onChange={() => false} checked={getNE(formData,val)} />
                      <label className="custom-control-label" onClick={() => setFormData(getUPE(formData, val, !getNE(formData, val)))} ></label>
                    </div>
                  ) : null}
                  {val.type === "radio" ? (
                    <React.Fragment>
                      {val.list?.map((radio,key)=>(
                        <div key={key} className="form-check mb-1" onClick={() => {
                            let tempForm = getUPE(formData, val, radio.value)
                            if(val.repeatRadio){
                              tempForm = {...tempForm, [val.repName]: tempForm[val.repName].map((t,k)=>(k !== val.repKey ? {...t, [getPN(val)] : "0"} : t))}
                            }
                            setFormData(tempForm);
                          }}>
                            <input className={(getMTU(val).classes || '')+" form-check-input"} type="radio" {...(getMTU(val).attributes || {})} {...(getNE(attributes, val) || {})} name={getPN(val)} onChange={() => false} value={radio.value} checked={getNE(formData,val) === radio.value} />
                            <label className="form-check-label">{radio.label}</label>
                        </div>
                      ))}
                    </React.Fragment>
                  ): null}
                  {val.type === "mask" ? (
                    <InputMask {...getAttr(val)} />
                  ): null}
                  {val.type === "datepicker" ? (
                    <Flatpickr {...getAttr(val)} />
                  ) : null}
                  {val.type === "wysiwyg" ? (
                    <Editor {...getAttr(val)} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" />
                  ) : null}
                  {val.type === "file" ? (
                    <div className="custom-file">
                        <input type={val.type} {...(getNE(attributes, val) || {})} className={(getMTU(val).classes || '')+" custom-file-input"} onChange={async(e) => {
                          setFormData(getUPE(formData, val, await toBase64(e.target.files[0]) || {}));
                          setCurrentFiles(getUPE(currentFiles, val, e.target.files[0]?.name))
                        }} />
                        <label className="custom-file-label">{getNE(currentFiles, val)?.split("/")[getNE(currentFiles, val)?.split("/").length-1] || "Choose File"}</label>
                    </div>
                  ) : null}
                  {val.type === "dropzone" ? (
                    <React.Fragment>
                      <Dropzone onDrop={async(e) => await uploadDzFile(e, val)}>
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3"><i className="display-4 text-muted bx bxs-cloud-upload"></i></div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div className="dropzone-previews mt-3" id="file-previews">
                        <Row>
                          {getNE(currentFiles, val)?.map((file, key) => {
                            return (
                              <Col xs={6} key={key + "-file"}>
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={key + "-file"}>
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img data-dz-thumbnail="" height="80" className="avatar-sm rounded bg-light" alt={file?.name} src={configData.API_PUBLIC_URL+file?.path} />
                                      </Col>
                                      <Col>
                                        <Link to="#" className="text-muted font-weight-bold">{file?.name}</Link>
                                        <p className="mb-0"><strong>{formatBytes(file?.size)}</strong></p>
                                      </Col>
                                      <Col align="right">
                                        <Button color='danger' disabled={submitDisabled} className='btn-sm waves-effect waves-light' onClick={(e) => removeDzFile(val, file, false, e)}>
                                            <i className='dripicons-cross d-block font-size-14'></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </React.Fragment>
                  ) : null}
                  {val.type === "repeater" ? (
                    <React.Fragment>
                        {getNE(formData,val).map((_,key)=>(
                          <Row key={key} className="mb-2">
                            <Col xs={12-(getMTU(val).deleteBtnSize || 1)}>
                              <Row>
                                {renderInputs(val.children.map((c)=>({...c, repName: getPN(val), repKey: key, repeatLabel: getMTU(val).repeatLabel})))}
                                {val.divider ? <hr style={{width:'100%'}} /> : null}
                              </Row>
                            </Col>
                            <Col xs={getMTU(val).deleteBtnSize || 1}>
                              <Button disabled={getNE(formData,val).length === 1 || submitDisabled} color='danger' className=' waves-effect waves-light' style={key === 0 || getMTU(val).repeatLabel ? {marginTop: '2em'} : null} onClick={(e) => deleteRepeaterRowPre(e,val,_,key)}>
                                <i className='mdi mdi-trash-can d-block font-size-13'></i>
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      <Button color='success' className=' waves-effect waves-light' onClick={() => addRepeaterRow(val)}>
                        <i className='mdi mdi-plus d-block font-size-13'></i>
                      </Button>
                    </React.Fragment>
                  ) : null}
                </FormGroup>
              </Col>
            </React.Fragment>
          ) : null}
          {getMTU(val)?.enabled && (!getNE(attributes,val)?.hidden) && config.type === "View" ? (
            <React.Fragment>
              {((data[key-1] && val.section?.id && val.section?.id !== data[key-1].section?.id) || (!data[key-1] && val.section?.id)) ? (
                <Col xs={12}>{data[key-1] ? <hr /> : null}<h5 className={data[key-1] ? "mt-3 mb-3" : "mb-3"}><strong>{val.section?.label}</strong></h5></Col>
              ) : null}
              <Col className={(key+1)%4===0 || (key)%4===0 ? "altColn" : ""} key={key} xs={getMTU(val).size || 6} data-order={val.order.form} style={{borderBottom: '1px solid #f0f0f0'}}>
                <FormGroup style={{marginTop:'0.5rem'}}>
                  { !(val.repKey && val.repKey > 0) || val.repeatLabel ? (
                    <Label for={val.label}><strong>{val.label}</strong></Label>
                  ) : null}
                  {val.type === "repeater" ? (
                    <React.Fragment>
                      {getNE(formData,val).map((_,key)=>(
                        <Row key={key} className="mb-2">
                          {renderInputs(val.children.map((c)=>({...c, repName: getPN(val), repKey: key, repeatLabel: getMTU(val).repeatLabel})))}
                          {val.divider ? <hr style={{width:'100%'}} /> : null}
                        </Row>
                      ))}
                    </React.Fragment>
                  ) : null}
                  {val.type === "select" ? (
                    <p><i>{getNE(selects,val).label}</i></p>
                  ) : null}
                  {val.type === "multiselect" ? (
                    <p><i>{getNE(selects,val) ? getNE(selects,val).map((v,k)=>(v.label+(k+1 === getNE(selects,val).length ? "" : ", "))) : ""}</i></p>
                  ) : null}
                  {val.type === "switch" || val.type === "checkbox" ? (
                    <p><i>{getNE(formData,val) ? "Yes" : "No"}</i></p>
                  ) : null}
                  {val.type === "wysiwyg" ? (
                    <Editor readOnly toolbarHidden {...getAttr(val)} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" />
                  ) : null}
                  {val.type === "file" ? (
                    <><br /><img style={{maxWidth:"100%"}} src={configData.API_PUBLIC_URL+getNE(currentFiles, val)} alt={val.label} /></>
                  ) : null}
                  {val.type === "dropzone" ? (
                    <Row>{getNE(currentFiles, val).map((v,k)=>(<Col key={k} xs={4}><img style={{maxWidth:"100%"}} src={configData.API_PUBLIC_URL+get(v, val.subFields?.path)} alt={get(v, val.subFields?.name)} /></Col>))}</Row>
                  ) : null}
                  {val.type !== "repeater" && val.type !== "select" && val.type !== "multiselect" && val.type !== "switch" && val.type !== "checkbox" && val.type !== "wysiwyg" && val.type !== "file" && val.type !== "dropzone" ? (
                    <div style={{width:'100%', padding:'10px', overflow:'auto'}}><i>{getNE(formData,val)}</i></div>
                  ) : null}
                  
                </FormGroup>
              </Col>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ))
    }

    // useEffect(() => {
    //   console.log(attributes)
    // }, [attributes])

    return (
      <Modal size={config.size} isOpen={config.visible} toggle={()=>toggleModal()} keyboard={false}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{!config.absoluteHeader ? config.type + " " : ""}{config.header}</h5>
            <button type="button" onClick={() => toggleModal()} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <React.Fragment>
              <div id="modalBody" className="modal-body">
                {/* Lightweight Loader */}
                {/* <div className="spinner-border text-primary m-1" role="status" style={{position: 'absolute', top:'45%',left:'48%', display: formLoading ? "block" : "none"}}>
                    <span className="sr-only">Loading...</span>
                </div> */}
                <div id="preloader" style={{position: 'absolute', display: formLoading ? "block" : "none"}}>
                  <div id="status" style={{display: formLoading ? "block" : "none"}}>
                    <div className="spinner-chase">
                      <div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div><div className="chase-dot"></div>
                    </div>
                  </div>
                </div>
                <Popover onClick={()=>toggleConfirmDialog()} placement="bottom" open={confirmDialog.visible} anchorReference="anchorPosition" anchorPosition={{ top: confirmDialog.top, left: confirmDialog.left }}>
                    <PopoverHeader>Sure to delete?</PopoverHeader>
                    <PopoverBody>
                        <Row>
                            <Col xs={6}>
                                <Button color="primary" outline className="waves-effect waves-light">Cancel</Button>
                            </Col>
                            <Col xs={6}>
                                <Button onClick={() => confirmDialog.action.fn ? confirmDialog.action.fn(...confirmDialog.action.params) : false} color="danger" className="waves-effect waves-light">OK</Button>
                            </Col>
                        </Row>
                    </PopoverBody>
                </Popover>
                {!formLoading ? config.wizard && config.type !== "View" ? (
                  <div id="progrss-wizard" className="twitter-bs-wizard" style={{width:'100%'}}>
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        {wizardConfig.tabs?.map((tab,key)=>(
                          <NavItem key={key}>
                              <NavLink className={classnames({ active: wizardConfig.activeTab === (key+1) })} >
                                  <span className="step-number mr-2">{key+1 < 10 ? "0"+(key+1) : (key+1)}</span>
                                  {tab}
                              </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                      <div id="bar">
                          <Progress color="success" striped animated value={(wizardConfig.activeTab / wizardConfig.tabCount) * 100} />
                          <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                      </div>
                      <TabContent activeTab={wizardConfig?.activeTab} className="twitter-bs-wizard-tab-content">
                        {wizardConfig.tabs?.map((tab,key)=>(
                          <TabPane key={key+1} tabId={key+1}>
                            <Form id={"form-"+(key+1)} onSubmit={submitWizardForm}>
                              <Row className="ml-4 mr-4">
                                {renderInputs(ds.filter((val)=>(val.section?.label === tab)))}
                              </Row>
                            </Form>
                          </TabPane>
                        ))}
                      </TabContent>
                  </div>
                ) : (
                  <Form id="form-main" onSubmit={onSubmit}>
                    <Row>
                      {renderInputs(ds)}
                    </Row>
                  </Form>
                ) : <div style={{minHeight:'100px'}}></div>}
                  
              </div>
              <div className="modal-footer">
                {!formLoading && config.type !== "View" ? config.wizard ? (
                  <React.Fragment>
                    <Col>
                      <button style={{display: wizardConfig?.activeTab === 1 ? "none" : "inline-block"}} type="button" onClick={() => toggleWizardTab(wizardConfig.activeTab - 1)} className="btn btn-secondary waves-effect">Previous</button>
                    </Col>
                    <Col align="right">
                      <Button style={{display: wizardConfig?.activeTab === wizardConfig?.tabCount ? "none" : "inline-block"}} disabled={submitDisabled} form={"form-"+(wizardConfig?.activeTab)} color='success'>Next</Button>
                      <Button style={{display: wizardConfig?.activeTab === wizardConfig?.tabCount ? "inline-block" : "none"}} disabled={submitDisabled} form={"form-"+(wizardConfig?.activeTab)} color='primary'>Save</Button>
                    </Col>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <button type="button" onClick={() => toggleModal()} className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
                    <Button disabled={submitDisabled} form="form-main" color='primary'>Save</Button>
                  </React.Fragment>
                ) : <button type="button" onClick={() => toggleModal()} className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>}
              </div>
          </React.Fragment>
      </Modal>
    )
}