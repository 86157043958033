import React, { useEffect,useState } from "react";
import { Card, CardBody, Spinner,Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const CardUser = props => {
  const [dataReady, setDataReady] = useState(false);
  

  useEffect(()=>{
    if(props.data[3]) {
      setDataReady(true)
    }    
  },[props.data[3]])

  const { options, series } = props
  return (
    <React.Fragment>
      <Col xl={12}>
        <Row>
          <Col lg={3}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="media" style={{height:'46px'}}>
                  <div className="media-body">
                    <div className="media">
                      <div className="media-body">
                        <div className="text-muted">
                          {dataReady?<h5 className="mb-0">{props.data[0].name}</h5>:<Spinner size="sm" color="primary" />}<br/>
                          <div className="mb-0">{dataReady?<span><strong>{props.data[1].allow_without_credit=='Y'?'Post Paid User':'Pre Paid User'}</strong> | <span>Member since {props.data[0].created}</span></span>:<Spinner size="sm" color="primary" />}</div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="mini-stats-wid">
              <CardBody>
                <Row>
                      <Col md={6}>
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Total Reload Credits
                          </p>
                          {dataReady?<h5 className="mb-0">{props.data[2].topups}</h5>:<Spinner size="sm" color="primary" />}
                        </div>
                      </Col>
                      <Col md={6}>
                      <div>
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Balance
                          </p>
                          {dataReady?<h5 className="mb-0">{props.data[1].balance}</h5>:<Spinner size="sm" color="primary" />}
                        </div>
                      </div>
                      </Col>
                    </Row>
              </CardBody>
            </Card>
          </Col>


          <Col lg={2}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="mr-3">
                    <p className="text-muted mb-2">Total Sent</p>
                    <h5 className="mb-0"> {dataReady?props.data[3].messageCount:<Spinner size="sm" color="primary" />}</h5>
                  </div>

                  <div className="avatar-sm ml-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="fa fa-comment-dots"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={2}>
            <Card className="blog-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="mr-3">
                    <p className="text-muted mb-2">Total Groups</p>
                    <h5 className="mb-0">{dataReady?props.data[9].groupsCount:<Spinner size="sm" color="primary" />}</h5>
                  </div>

                  <div className="avatar-sm ml-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="mdi mdi-account-group" style={{fontSize:'22px'}}></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={2}>
            <Card className="blog-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="mr-3">
                    <p className="text-muted mb-2">Total Contacts</p>
                    <h5 className="mb-0">{dataReady?props.data[10].contactCount:<Spinner size="sm" color="primary" />}</h5>
                  </div>

                  <div className="avatar-sm ml-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="fa fa-address-card"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <h5 className="card-title mr-2">Messages Sent</h5>
            </div>

            <Row className="text-center">
              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted mb-1">Today</p>
                  <h5 className="mb-0"> {dataReady?props.data[4].messageToday:<Spinner size="sm" color="primary" />}</h5>
                </div>
              </Col>

              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted mb-1">This Month</p>
                  <h5 className="mb-0"> {dataReady?props.data[5].messageMonth:<Spinner size="sm" color="primary" />}</h5>
                </div>
              </Col>

              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted mb-1">This Year</p>
                  <h5 className="mb-0"> {dataReady?props.data[6].messageYear:<Spinner size="sm" color="primary" />}</h5>
                </div>
              </Col>
            </Row>

            <hr className="mb-4" />
            <div className="apex-charts" id="area-chart" dir="ltr">
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={350}
                width={'100%'}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default CardUser
