import { atom } from "recoil";

export const pageState = atom({
    key: 'pageState',
    default: null,
})

export const lastAPIActionTimeState = atom({
    key: 'lastAPIActionTimeState',
    default: null,
})

export const preloaderState = atom({
    key: 'preloaderState',
    default: true,
})

export const isMainLoadingState = atom({
    key: 'isMainLoadingState',
    default: false,
})

export const pageLoaderState = atom ({
    key: 'pageLoaderState',
    default: false,
})

export const tokenNeedsRefreshState = atom({
    key: 'tokenNeedsRefreshState',
    default: false,
})


export const notificationSettingsState = atom({
    key: 'notificationSettingsState',
    default: {
        enabled: false,
        enableViewAll: false, // enable this if there is a notification page
        notificationPage: '#', // page to redirect to for full notification list
        height: '230px',
    },
})

export const layoutTypeState = atom({
    key: 'layoutTypeState',
    default: 'vertical',
})

export const condensedMenuEnabledState = atom({
    key: 'condensedMenuEnabledState',
    default: true,
})

export const leftSidebarTypeState = atom({
    key: 'leftSidebarTypeState',
    default: 'default',
})