
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/ScheduleController";

export const scheduleListState = atom({
    key: 'scheduleListState',
    default: [],
})

export const modalConfigDefault = { size: "md", wizard: false }

export const filterConfigDefault = {
    filter: false,
}

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    view: [ { name: 'Default View' } ],
}

export const scheduleDataStructureState = selector({
    key: 'scheduleDataStructureState',
    get: () => (
        [
            { 
                label: "Signature",
                type: "text",
                order: { table: 1, form:1 },
                create: [{ enabled: true, size: 12 }],  view: [ 'same_as:create.0' ],
            },
            { 
                label: "Recipient",
                type: "text",
                order: { table: 0, form:0},
                create: [{ enabled: true, size: 12 }],  view: [ 'same_as:create.0' ],
            },
            { 
                label: "Sender Id",
                type: "text",
                order: { table: 2, form:2 },
                create: [{ enabled: true, size: 12 }],  view: [ 'same_as:create.0' ],
            },
            { 
                label: "Status",
                type: "text",
                field:'processed',
                order: { table: 5, form:5 },
                create: [{ enabled: true, size: 12 }],  view: [ 'same_as:create.0' ],
            },
            { 
                label: "Message Content",
                type: "text",
                field: "msg",
                order: { table: 3, form:3 },
                create: [{ enabled: true, size: 12 }],  view: [ 'same_as:create.0' ],
            },
            { 
                label: "Schedule Datetime",
                type: "text",
                order: { table: 4, form:4 },
                create: [{ enabled: true, size: 12 }], update: [{enabled:false}], view: [ 'same_as:create.0' ],
            },       
        ]
    )
})







