import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// Redux
import { Link, useLocation, withRouter } from "react-router-dom";
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
// action
// import images
import profile from "../../assets/images/profile-img.png";
// import logo from "../../assets/images/logo.svg";

import logo from "../../assets/images/logo-light.svg"

import { validatePasswordReset, initPasswordReset } from "../../api/controller/AuthController";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import { preloaderState } from "../../state/GlobalState";
import notify from "../../custom/helpers/Notify";
import { passwordResetHashState } from "../../api/state/AuthState";

const ForgotPassword = ({history}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [preloader, setPreloader] = useRecoilState(preloaderState)
  const setPasswordResetHash = useSetRecoilState(passwordResetHashState)

  const location = useLocation()

  useEffect(() => {
    setPreloader(true)
    if(location.search){
      var params = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      if(params.ref){
        async function passwordResetFn(params){
          const response = await validatePasswordReset({ ref: params.ref })
          if(response?.Status === "success" && response?.Ref){
            setPasswordResetHash(response.Ref)
            setPreloader(false)
            history.push("/change-password")
          } else {
            setPreloader(false)
            notify({ status: 'error', message: "Invalid Password Reset Link. Please try again later!" });
            history.push("/login")
          }
        }
        passwordResetFn(params)
      } else {
        setPreloader(false)
        history.push("/login")
      }
    } else {
      setPreloader(false)
    }
  }, [history, location.search, setPasswordResetHash, setPreloader]);

  // handleValidSubmit
  const handleValidSubmit = async(event, values) => {
    setSubmitDisabled(true)
    const response = await initPasswordReset({ Email: values.email })
    handleResponse(response)
    setSubmitDisabled(false)
  }


  return (
    preloader ? (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
    ) : (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Forgotten password ?</h5>
                          <p>Enter your email to continue.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          {/* <span className="avatar-title rounded-circle bg-light"> */}
                            <img src={logo} alt="" className="rounded-circle" height="60" />
                          {/* </span> */}
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">

                      <AvForm className="form-horizontal mt-4" onValidSubmit={handleValidSubmit}>
                        <div className="form-group">
                          <AvField name="email" label="Email" className="form-control" placeholder="Enter email" type="email" required />
                        </div>
                        <Row className="form-group">
                          <Col className="text-right">
                            <button className="btn btn-primary w-md waves-effect waves-light" disabled={submitDisabled} type="submit">Reset</button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>Go back to{" "}<Link to="login" className="font-weight-medium text-primary">Login</Link>{" "}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  );
}
export default withRouter(ForgotPassword);
