
import http from "../core/http";
const group = 'message';

export const sendNewMessageRequest =  async (data) => {
    return await http.post(`${group}/send`, data).then(res => res.data).catch(http.defaultCatch);
}
export const checkProgress =  async (data) => {
    return await http.post(`${group}/messageProgress`, data).then(res => res.data).catch(http.defaultCatch);
}





// export const createDataRequest =  async (data) => {
//     return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
// }

// export const updateDataRequest =  async (data) => {
//     return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
// }

// export const deleteDataRequest =  async (data) => {
//     return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
// }