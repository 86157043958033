
import http from "../core/http";
const group = 'users';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

export const resetPasswordRequest =  async (data) => {
    return await http.post(`${group}/password/reset`, data).then(res => res.data).catch(http.defaultCatch);
}



export const getRolesListRequest =  async (data) => {
    return await http.post(`roles/get`, data).then(res => res.data).catch(http.defaultCatch);
}