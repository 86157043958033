import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Card, CardBody, Col, Container, Row, Button, Label } from 'reactstrap';
import notify from '../../../custom/helpers/Notify';
import { MDBDataTable } from 'mdbreact';
import '../../../pages/Tables/datatables.scss';
import { ActionButtons } from '../../../custom/components/ActionButtons';
import { ConfirmDialog } from '../../../custom/components/ConfirmDialog';
import { pageState, lastAPIActionTimeState } from '../../../state/GlobalState';
import { HasPermission } from '../../../custom/helpers/HasPermission';
import { DynamicModal } from '../../../custom/components/DynamicModal';
import { cloneDeep, get,set } from 'lodash';
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import { formModes, filterConfigDefault, modalConfigDefault, templateDataStructureState as dataStructureState, templateListState as dataListState } from './state'; // CHANGE (dataList & structure stateName)
import { getDataListRequest, deleteDataRequest } from '../../../api/controller/TemplateController'; // CHANGE (controller name)

const Templates = () => {
  
  const model = 'Template' // CHANGE
  const models = 'Templates' // CHANGE
  
  const selects = []
  const [dataList, setDataList] = useRecoilState(dataListState);
  const setPage = useSetRecoilState(pageState)
  const lastAPIActionTime = useRecoilValue(lastAPIActionTimeState)

  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [confirmID, setConfirmID] = useState(null);
  const [confirmParams, setConfirmParams] = useState({});
  const [confirmConfig, setConfirmConfig] = useState({ fn: null, title: null, body: null });
  const [cuModalConfig, setCUModalConfig] = useState({visible: false, header: model, type: null, typeKey: null, data: {}, ...modalConfigDefault})
  
  const [filterConfig, setFilterConfig] = useState(filterConfigDefault)
  const dataStructure = useRecoilValue(dataStructureState)

  useEffect(() => {
    setPage(models.toLowerCase()) // CHANGE
  }, [setPage])

  useEffect(() => {
    async function fetchData(filter){
      const response = await getDataListRequest(filter);
      response && response.Status !== 'error' ? setDataList(response.data) : notify({ status: 'error', message: response.Message });
    }
    const filter = filterConfig.filter ? { Filter: filterConfig.type === "select" ? filterConfig.filterValue.value : filterConfig.filterValue } : null
    fetchData(filter)
  }, [lastAPIActionTime, setDataList, filterConfig.filterValue, filterConfig.filter, filterConfig.type]);

  useEffect(() => {
    async function fetchSelectData(){
      await Promise.all(selects.map(async(select)=>{
        const response = await select.getFn();
        response && response.Status !== 'error' ? select.setFn(response.data?.map((val)=>({ label: val.name, value: val.id }))) : notify({ status: 'error', message: response.Message });
      }))
    }
    fetchSelectData();
    // eslint-disable-next-line
  }, [])
  
  const toggleCUModal = (row={}, mode=null, modeKey=0, size=null) => {
    setCUModalConfig({ ...cuModalConfig, visible: !cuModalConfig.visible, type: typeof(mode) === 'string' ? mode : null, typeKey: modeKey, data: row, size: size || cuModalConfig.size })
  }

  const toggleConfirmModal = (row={}, params={}, request=null, title=null, body=null) => {
    setConfirmID(row ? row.id : null)
    setConfirmParams(params)
    setConfirmConfig({ fn: request, title: title, body: body })
    setConfirmModalVisible(! confirmModalVisible)
  }


  const actionListType = 'buttons'
  const actions = useMemo(() => ([
    {
      label: 'View',
      permission: 'read',
      icon: 'mdi mdi-eye',
      color: 'primary',
      fn: toggleCUModal,
      params: ["View", 0, "lg"]
    },
    {
      label: 'Update',
      permission: 'update',
      icon: 'mdi mdi-pencil',
      color: 'success',
      fn: toggleCUModal,
      params: ["Update"]
    },
    {
      label: 'Delete',
      permission: 'delete',
      icon: 'mdi mdi-trash-can',
      color: 'danger',
      fn: toggleConfirmModal,
      params: [{}, deleteDataRequest]
    }
    // eslint-disable-next-line
  ]), []);

  const data = useMemo(() => ({
    columns: [...dataStructure.filter((val) => val.order?.table).sort((a,b)=>(a.order?.table > b.order?.table ? 1 : -1)).map((val) => (
      {label: val.label, field: ((val.field || val.label.replace(/ /g, '_').toLowerCase()).replace(/\./g, '') + (val.subFields || (val.type === "select" && !val.rootValue) ? val.subFields?.label || val.subFields?.name || "name" : "" )).replace(/\./g, '')}
    )), { label: "Actions", field: 'actions' }],
    rows: dataList?.map((row) => {
      let tempRow = cloneDeep(row)
      dataStructure.filter((input) => input.order?.table).forEach((input) => {
        const field = input.subFields || (input.type === "select" && !input.rootValue) ? (input.subFields?.label || input.subFields?.name || input.type === "select" ? ((input.field || input.label.replace(/ /g, '_').toLowerCase()) + "." + (input.subFields?.label || input.subFields?.name || "name")) : (input.field || input.label.replace(/ /g, '_').toLowerCase())) : (input.field || input.label.replace(/ /g, '_').toLowerCase());
        set(tempRow, field.replace(/\./g, ''), get(tempRow,field))
      })
      set(tempRow, 'actions', <ActionButtons actions={actions} type={actionListType} row={row} />)
      return tempRow
    }),
  }), [dataStructure, dataList, actions]);

  
  const changeFilter = (e) => {
    if(filterConfig.type === "dateRange" && e.split(" to ").length > 1){
      setFilterConfig({...filterConfig, filterValue: e.split(" to ")})
    } else if(filterConfig.type === "date"){
      setFilterConfig({...filterConfig, filterValue: e})
    } else if(filterConfig.type === "select"){
      setFilterConfig({...filterConfig, filterValue: filterConfig.list.filter((o)=>(parseInt(o.value) === parseInt(e.value)))[0]})
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col span={12}>
              <h3>
                {models}{" "}
                  {HasPermission("create") ? (
                    <Button color='primary' className='ml-2 waves-effect waves-light btn-sm' type='submit' onClick={() => toggleCUModal({}, "Create")}>
                      <i className='mdi mdi-plus d-block font-size-16'></i>
                    </Button>
                  ) : null}
                </h3>
            </Col>
            <Col span={6}></Col>
            <Col span={6} align="right">
              {filterConfig.filter ? (
                <Row>
                  <Col xs={3}>
                    <Label className="mt-2">{filterConfig.label}</Label>
                  </Col>
                  <Col xs={9}>
                    {filterConfig.type === "date" ? (
                      <Flatpickr onChange={(_,e) => changeFilter(e)} options={{ altInput: true, defaultDate: filterConfig.default }} value={filterConfig.filterValue} className="form-control d-block" />
                    ) : null}
                    {filterConfig.type === "dateRange" ? (
                      <Flatpickr onChange={(_,e) => changeFilter(e)} options={{ mode:'range', altInput: true, defaultDate: filterConfig.default }} value={filterConfig.filterValue} className="form-control d-block" />
                    ) : null}
                    {filterConfig.type === "select" ? (
                      <Select onChange={(e) => changeFilter(e)} options={filterConfig.list} value={filterConfig.filterValue} />
                    ) : null}
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable responsive striped bordered noBottomColumns data={data} />
                  </CardBody>
                </Card>
              </Col>
          </Row>

          <DynamicModal config={cuModalConfig} toggleModal={toggleCUModal} submitModes={formModes} ds={dataStructure} />

          <ConfirmDialog id={confirmID} params={confirmParams} config={confirmConfig} visible={confirmModalVisible} toggleModal={toggleConfirmModal} />

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Templates);
