import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Card, CardBody, Col, Container, Modal, Row, Button, Label,    
  Nav, NavItem, NavLink,
  TabContent,
  FormGroup,Spinner,
  TabPane, 
  Input,
} from 'reactstrap';
import classnames from 'classnames'
import notify from '../../../custom/helpers/Notify';
import { MDBDataTable } from 'mdbreact';
import '../../../pages/Tables/datatables.scss';
import { ActionButtons } from '../../../custom/components/ActionButtons';
import { ConfirmDialog } from '../../../custom/components/ConfirmDialog';
import { pageState, lastAPIActionTimeState } from '../../../state/GlobalState';
import { HasPermission } from '../../../custom/helpers/HasPermission';
import { DynamicModal } from '../../../custom/components/DynamicModal';
import { cloneDeep, get,set } from 'lodash';
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import "flatpickr/dist/themes/material_blue.css"
import { formModes, filterConfigDefault, modalConfigDefault, groupDataStructureState as dataStructureState, groupListState as dataListState } from './state'; // CHANGE (dataList & structure stateName)
import { getDataListRequest, deleteDataRequest,createDataRequest,downloadContact } from '../../../api/controller/GroupController'; // CHANGE (controller name)
import { roleListState } from '../roles/state'; // CHANGE (SELECT STEP 2)
import { useHistory } from "react-router-dom";
import CSVParse, {CSVImportState,handleRemoveFile} from '../../../custom/components/CSVParse'
 


const Group = () => {
  const [modalNewGroup, setModalNewGroup] = useState(false)
  const [modalUpdateGroup, setModalUpdateGroup] = useState(false)
  const [groupId, setGroupId] = useState()
  const [groupName, setGroupName] = useState('')
  const [activeTab, setactiveTab] = useState("1")
  const [formData, setFormData] = useState({})
  const [recipients, setRecipients] = useState(0)
  const [importContacts, setImportContacts] = useState()
  let history = useHistory();
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const model = 'Group' // CHANGE
  const models = 'Groups' // CHANGE
  
  const selects = []
  const [dataList, setDataList] = useRecoilState(dataListState);
  const setPage = useSetRecoilState(pageState)
  const lastAPIActionTime = useRecoilValue(lastAPIActionTimeState)

  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [confirmID, setConfirmID] = useState(null);
  const [confirmParams, setConfirmParams] = useState({});
  const [confirmConfig, setConfirmConfig] = useState({ fn: null, title: null, body: null });
  const [cuModalConfig, setCUModalConfig] = useState({visible: false, header: model, type: null, typeKey: null, data: {}, ...modalConfigDefault})
  
  const [filterConfig, setFilterConfig] = useState(filterConfigDefault)
  const dataStructure = useRecoilValue(dataStructureState)
  const [contactsFromCSVImport] = useRecoilValue(CSVImportState)


  useEffect(() => {
    setPage(models.toLowerCase()) // CHANGE
  }, [setPage])

  useEffect(() => {
    async function fetchData(filter){
      const response = await getDataListRequest(filter);
      response && response.Status !== 'error' ? setDataList(response.data) : notify({ status: 'error', message: response.Message });
    }
    const filter = filterConfig.filter ? { Filter: filterConfig.type === "select" ? filterConfig.filterValue.value : filterConfig.filterValue } : null
    fetchData(filter)
  }, [lastAPIActionTime, setDataList, submitted, filterConfig.filterValue, filterConfig.filter, filterConfig.type]);

  useEffect(() => {
    async function fetchSelectData(){
      await Promise.all(selects.map(async(select)=>{
        const response = await select.getFn();
        response && response.Status !== 'error' ? select.setFn(response.data?.map((val)=>({ label: val.name, value: val.id }))) : notify({ status: 'error', message: response.Message });
      }))
    }
    fetchSelectData();
    // eslint-disable-next-line
  }, [])

  // get parase
  useEffect(()=>{
    console.log(contactsFromCSVImport)
    let contactsImported = contactsFromCSVImport&&JSON.parse(JSON.stringify(contactsFromCSVImport));     
    let contacts = '';
    contactsFromCSVImport&&contactsImported.map((data) => 
        contacts += data.data + ","
    )
    contacts = contacts.replace(/,\s*$/, "");
    contacts = contacts.replace(/,\s*$/, "");
    setImportContacts(contacts)
    // update recipient counter
    let contactsList = contactsFromCSVImport&&contacts.split(',')
    contactsFromCSVImport&&setRecipients(contactsList&&contactsList.length)


},[contactsFromCSVImport])
  
function toggle(tab) {
  if (activeTab !== tab) {
    setactiveTab(tab)
    formData.contacts = '';
    formData.importContacts = '';
    setRecipients(0);
    setImportContacts()
  }
}
const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value
  });
  if(e.target.name == 'contacts' && formData.contacts ) {
      let contacts = formData.contacts.split(',')
      setRecipients(contacts&&contacts.length)
  }
   
};

const createNewGroup = async (event) => {
  //validate
  if(formData.groupName == '' || formData.groupName == null || formData.groupName == undefined) {
    notify({ status: 'error', message: "Please enter group name!"});
    return false;
  } else if(recipients < 1) {
      notify({ status: 'error', message: "Please enter atlease one recipient"});
      return false;
  } else {
      // disable send button with progress loader
      setSubmitting(true);
      let data = null;
      if(activeTab == 1) {
          data = {'recipient':formData.contacts, 'Name':formData.groupName}
      } else if(activeTab == 2) {
          data = {'recipient':importContacts, 'Name':formData.groupName}
      } 
      const response = await createDataRequest(data);
      // response && response.Status !== 'error' ? null : notify({ status: 'error', message: response.Message });
      if((response?.Status === "success")){
        setSubmitted(true)
        setModalNewGroup(false)
        setSubmitted(false)
      } else {
        notify({ status: 'error', message: response.payload.errors.Name })
      }
      setSubmitting(false);
    
    }
}


const updateGroup = async (event) => {
  //validate
  if(recipients < 1) {
      notify({ status: 'error', message: "Please enter atlease one recipient"});
      return false;
  } else {
      // disable send button with progress loader
      setSubmitting(true);
      let data = null;
      if(activeTab == 1) {
          data = {'recipient':formData.contacts, 'Name':groupName?groupName:"", 'Id' : groupId }
      } else if(activeTab == 2) {
          data = {'recipient':importContacts, 'Name':formData.groupName,'Id' : groupId}
      } 
      const response = await createDataRequest(data);
      if((response?.Status === "success")){
        setSubmitted(true)
        setModalNewGroup(false)
        setSubmitted(false)
      } else {
        notify({ status: 'error', message: response.payload.errors.Name })
      }
      setSubmitting(false);
    
    }
}

const newGroupModal = () => {
  // new group
  setModalNewGroup(!modalNewGroup)
  setRecipients(0);
  setImportContacts()

  // update group
  setModalUpdateGroup(false) // disable update
  setGroupId() // reset
  setGroupName('')
  formData.contacts = '';
  formData.importContacts = '';


}

 
const toggleCUModal = (row={}, mode=null, modeKey=0, size=null) => {
  setCUModalConfig({ ...cuModalConfig, visible: !cuModalConfig.visible, type: typeof(mode) === 'string' ? mode : null, typeKey: modeKey, data: row, size: size || cuModalConfig.size })
}

const toggleConfirmModal = (row={}, params={}, request=null, title=null, body=null) => {
  setConfirmID(row ? row.id : null)
  setConfirmParams(params)
  setConfirmConfig({ fn: request, title: title, body: body })
  setConfirmModalVisible(! confirmModalVisible)
}

const customFunc = (row={}) => {
  // alert(row ? row.id : null);
  history.push({
    pathname: '/contacts',
    state: { 'groupId': row&&row.id }
  })
}

const importContactsFromModal = (row={}) => {
  let groupId=row&&row.id;
  let groupName=row&&row.name;
  newGroupModal()
  setModalUpdateGroup(true)
  setGroupId(groupId)
  setGroupName(groupName)
}

const downloadContactList = (row={}) => {
  downloadContact(row);
}



const actionListType = 'buttons'
const actions = useMemo(() => ([
  {
    label: 'View',
    permission: 'read',
    icon: 'mdi mdi-eye',
    color: 'primary',
    fn: toggleCUModal,
    params: ["View", 0, "lg"]
  },
  {
    label: 'Update',
    permission: 'update',
    icon: 'mdi mdi-pencil',
    color: 'success',
    fn: toggleCUModal,
    params: ["Update"]
  },
  {
    label: 'Delete',
    permission: 'delete',
    icon: 'mdi mdi-trash-can',
    color: 'danger',
    fn: toggleConfirmModal,
    params: [{}, deleteDataRequest]
  },
  {
    label: 'Add Contacts (Manual)',
    permission: 'delete',
    icon: 'mdi mdi-account-plus',
    color: 'info',
    fn: customFunc,
    params: [{}]
  },
  {
    label: 'Import Contacts',
    permission: 'delete',
    icon: 'mdi mdi-folder-upload-outline',
    color: 'info',
    fn: importContactsFromModal,
    params: [{}]
  },
  {
    label: 'Download Contacts',
    permission: 'delete',
    icon: 'mdi mdi-download',
    color: 'warning',
    fn: downloadContactList,
    params: [{}]
  }
  // eslint-disable-next-line
]), []);


const data = useMemo(() => ({
  columns: [...dataStructure.filter((val) => val.order?.table).sort((a,b)=>(a.order?.table > b.order?.table ? 1 : -1)).map((val) => (
    {label: val.label, field: ((val.field || val.label.replace(/ /g, '_').toLowerCase()).replace(/\./g, '') + (val.subFields || (val.type === "select" && !val.rootValue) ? val.subFields?.label || val.subFields?.name || "name" : "" )).replace(/\./g, '')}
  )), { label: "Actions", field: 'actions' }],
  rows: dataList?.map((row) => {
    let tempRow = cloneDeep(row)
    dataStructure.filter((input) => input.order?.table).forEach((input) => {
      const field = input.subFields || (input.type === "select" && !input.rootValue) ? (input.subFields?.label || input.subFields?.name || input.type === "select" ? ((input.field || input.label.replace(/ /g, '_').toLowerCase()) + "." + (input.subFields?.label || input.subFields?.name || "name")) : (input.field || input.label.replace(/ /g, '_').toLowerCase())) : (input.field || input.label.replace(/ /g, '_').toLowerCase());
      set(tempRow, field.replace(/\./g, ''), get(tempRow,field))
    })
    set(tempRow, 'actions', <ActionButtons actions={actions} type={actionListType} row={row} />)
    return tempRow
  }),
}), [dataStructure, dataList, actions]);


const changeFilter = (e) => {
  if(filterConfig.type === "dateRange" && e.split(" to ").length > 1){
    setFilterConfig({...filterConfig, filterValue: e.split(" to ")})
  } else if(filterConfig.type === "date"){
    setFilterConfig({...filterConfig, filterValue: e})
  } else if(filterConfig.type === "select"){
    setFilterConfig({...filterConfig, filterValue: filterConfig.list.filter((o)=>(parseInt(o.value) === parseInt(e.value)))[0]})
  }
}

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col span={12}>
              <h3>
                {models}{" "}
                  {HasPermission("create") ? (
                    <Button color='primary' className='ml-2 waves-effect waves-light btn-sm' type='submit' onClick={() => newGroupModal({}, "Create")}>
                      <i className='mdi mdi-plus d-block font-size-16'></i>
                    </Button>
                  ) : null}
                </h3>
            </Col>
            <Col span={6}></Col>
            <Col span={6} align="right">
              {filterConfig.filter ? (
                <Row>
                  <Col xs={3}>
                    <Label className="mt-2">{filterConfig.label}</Label>
                  </Col>
                  <Col xs={9}>
                    {filterConfig.type === "date" ? (
                      <Flatpickr onChange={(_,e) => changeFilter(e)} options={{ altInput: true, defaultDate: filterConfig.default }} value={filterConfig.filterValue} className="form-control d-block" />
                    ) : null}
                    {filterConfig.type === "dateRange" ? (
                      <Flatpickr onChange={(_,e) => changeFilter(e)} options={{ mode:'range', altInput: true, defaultDate: filterConfig.default }} value={filterConfig.filterValue} className="form-control d-block" />
                    ) : null}
                    {filterConfig.type === "select" ? (
                      <Select onChange={(e) => changeFilter(e)} options={filterConfig.list} value={filterConfig.filterValue} />
                    ) : null}
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
          <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable responsive striped bordered noBottomColumns data={data} />
                  </CardBody>
                </Card>
              </Col>
          </Row>

          <DynamicModal config={cuModalConfig} toggleModal={toggleCUModal} submitModes={formModes} ds={dataStructure} />

          <ConfirmDialog id={confirmID} params={confirmParams} config={confirmConfig} visible={confirmModalVisible} toggleModal={toggleConfirmModal} />

          
          <Modal
            isOpen={modalNewGroup}
              toggle={() => {
                newGroupModal()
              }}
              size="lg"
              backdrop={'static'}
            >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                  {modalUpdateGroup?"Update":"New"} Group
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModalNewGroup(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                    <div className="form-group row">
                      <label className="col-md-2 col-form-label" for="groupName">Group Name</label>
                      <div className="col-md-10">
                       { modalUpdateGroup ? 
                        <input
                          className="form-control"
                          id="groupName"
                          name="groupName"
                          placeholder="Group Name"
                          required={true}
                          onChange={handleChange} 
                          value={groupName}
                        />
                        :  <input
                          className="form-control"
                          id="groupName"
                          name="groupName"
                          placeholder="Group Name"
                          required={true}
                          onChange={handleChange} 
                        /> }
                        <input
                          className="form-control"
                          id="groupId"
                          name="groupId"
                          value={groupId}
                          hidden={true}
                        />
                      </div>
                    </div>
                    <div className="form-group" style={{flex:1, width:'100%', padding:'5px', flexDirection:'column', alignContent:'center', }}>
                      <Nav tabs className="nav-tabs-custom">
                          <NavItem>
                              <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                  active: activeTab === "1",
                                  })}
                                  onClick={() => {
                                  toggle("1")
                                  }}
                              >
                                  Numbers
                              </NavLink>
                          </NavItem>
                          <NavItem>
                              <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                  active: activeTab === "2",
                                  })}
                                  onClick={() => {
                                  toggle("2")
                                  }}
                              >
                                  File Import
                              </NavLink>
                          </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} style={{width:'100%', alignContent:'center', alignItems:'center' }}>
                          <TabPane tabId="1" className="pt-3 pb-3">
                              <Row>
                                  <Col sm="12">
                                  <Label for="importContacts" style={{color:'#2a9d8f'}}>Number of contacts <h2 style={{color:'#fca311'}}>{recipients}</h2></Label>
                                      <Input
                                          type="textarea"
                                          className="form-control"
                                          id="contacts"
                                          name="contacts"
                                          rows={10} 
                                          value={formData.contacts}
                                          placeholder="Enter numbers of the recipients seperated by comma (,). Example: 7991363,7991364,7991365,.."
                                          onChange={handleChange} 
                                      />
                                  </Col>
                              </Row>
                          </TabPane>
                          <TabPane tabId="2" className="pt-3 pb-3">
                              <Row>
                                  <Col sm="12">
                                      <CSVParse />
                                          <Label for="importContacts" style={{color:'#2a9d8f'}}>Number of contacts <h2 style={{color:'#fca311'}}>{recipients}</h2></Label>
                                          <Input
                                              type="textarea"
                                              className="form-control"
                                              id="importContacts"
                                              name="importContacts"
                                              rows={10} 
                                              value={importContacts}
                                                
                                          />
                                  </Col>
                              </Row>
                          </TabPane>
                      </TabContent>
                    </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => {
                      newGroupModal()
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                    {!submitting?
                    <button  type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                      modalUpdateGroup ? updateGroup() : createNewGroup()
                    }}>
                        Create
                    </button>:<Spinner className="mr-2" color="primary" />}

                  {/* <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                      createNewGroup()
                    }}
                  >
                    Create
                  </button> */}
                </div>
         </Modal>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Group);
