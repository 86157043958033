
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/TemplateController";
import { roleListState } from "../roles/state";

export const templateListState = atom({
    key: 'templateListState',
    default: [],
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filterConfigDefault = {
    filter: false,
}

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const templateDataStructureState = selector({
    key: 'templateDataStructureState',
    get: ({ get }) => (
        [{ 
            label: "Id",
            type: "text",
            order: { table: 0, form: 0 },
            create: [{ enabled: false, size: 2 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },{ 
            label: "Title",
            type: "text",
            order: { table: 2, form: 2 },
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },{ 
            label: "Details",
            type: "textarea",
            order: { table: 3, form: 3 },
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          }]
    )
})