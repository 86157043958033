
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/GroupController";
import { roleListState } from "../roles/state";

export const groupListState = atom({
    key: 'groupListState',
    default: [],
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filterConfigDefault = {
    filter: false,
}

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const groupDataStructureState = selector({
    key: 'groupDataStructureState',
    get: ({ get }) => (
        [{ 
            label: "Name",
            type: "text",
            order: { table: 1, form: 1},
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{ 
            label: "Contact Count",
            name: "contact_count",
            type: "text",
            order: { table: 2, form: 2 },
            create: [{ enabled: false, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        }]
    )
})