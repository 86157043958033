
import { Container,Row,Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Button,
    Alert,
    CardText,
    Form,
    Input,
    Spinner,
    Progress,
    InputGroupAddon,
    InputGroupText,
    Nav, NavItem, NavLink,
    TabContent,
    TabPane,
    InputGroup, } from "reactstrap";
import React, { useEffect,useState } from "react";
import notify from '../../../custom/helpers/Notify';
import SweetAlert from "react-bootstrap-sweetalert"
import classnames from 'classnames'
import CSVParse, {CSVImportState,handleRemoveFile} from '../../../custom/components/CSVParse'
import {atom, useRecoilState,useRecoilValue} from 'recoil'
import { Scrollbars } from 'react-custom-scrollbars';


//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {sendNewMessageRequest,checkProgress} from '../../../api/controller/MessageController'
import {getGroups, getGroupContacts,getSenderIds} from '../../../api/controller/GroupController'
import {getDataListRequest} from '../../../api/controller/TemplateController'
import { handleResponse } from "../../../custom/helpers/HandleResponse";

import {checkAuthRequest} from '../../../api/controller/AuthController'
import { userState } from "../../../api/state/AuthState";


const Compose = () => {
    const [activeTab, setactiveTab] = useState("1")
    const [chars, setChars] = useState(0)
    const [recipients, setRecipients] = useState(0)
    const [formData, setFormData] = useState({})
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState('')
    const [alertDescription, setAlertDescription] = useState('')
    const [alertType, setAlertType] = useState('success')
    const [progressNumber, setProgressNumber] = useState(0);
    const [queueProgressMessage, setQueueProgressMessage] = useState("WAITING")
    const [groupContacts, setGroupContacts] = useState()
    const [groupContactsReady, setGroupContactsReady] = useState(false)
    const [groups, setGroups] = useState()
    const [groupsReady, setGroupsReady] = useState(false);
    const [senderIds, setSenderIds] = useState();
    const [senderIdsReady, setSenderIdsReady] = useState(false);
    const [senderIdSelected, setSenderIdSelected] = useState();
    const [submitting, setSubmitting] = useState(false)
    const [scheduleMessage, setScheduledMessage] = useState(false)
    const [importContacts, setImportContacts] = useState()
    const [cost, setCost] = useState(0)
    const [senderIdCost, setSenderIdCost] = useState(0);
    const [contactsFromCSVImport] = useRecoilValue(CSVImportState)
    const [messageBox, setMessageBox] = useState(null)
    const [templates, setTemplates] = useState([])
    const [templatesReady, setTemplatesReady] = useState(false)
    const [PRCODE, setPRCODE] = useState()
    const [userInfo, setUserInfo] = useRecoilState(userState)
    const [calculatableCharacterLength, setCalculatableCharacterLength] = useState(160)
    const [mainMessageType, setMainMessageType] = useState('Text');

    var dhivehiChars = /[\u0780-\u07B1]/;

    // init
    useEffect(()=>{
        const init = async () => {
            formData.messageType = "instant";
            // get groups
            const groups = await getGroups(null);
            setGroups(groups);
            setGroupsReady(true);
            // get senderids
            const senderIds = await getSenderIds(null);
            setSenderIds(senderIds);
            setSenderIdsReady(true);
            // dynamically select first value of sender id
            senderIds.map((val,key)=> {
                if(key==0) {
                    formData.senderId  = val.sender_id;
                    setSenderIdSelected(val.sender_id)
                    setSenderIdCost(val.cost)
                }
            }); 
            // generate PRCODE
            if(PRCODE == '' | PRCODE == null || PRCODE == undefined) {
                const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = ' ';
                const charactersLength = characters.length;
                for ( let i = 0; i < 8; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                setPRCODE(result.toUpperCase());
            }

        }
        init();
        
        return(()=>{
            setQueueProgressMessage('WAITING')
        })
    },[])

    useEffect( async ()=>{
        // get message templates
        const templateData = await getDataListRequest(null);
        console.log(templateData)
        setTemplates(templateData.data);
        setTemplatesReady(true);
        return(()=>{})
    },[])

    // show scheduled input
    useEffect(()=>{
        if( formData.messageType == "scheduled") {
            setScheduledMessage(true)
        } else {
            setScheduledMessage(false);
            formData.scheduledDate = null;
            //clear progress message
            setQueueProgressMessage('');
        }
        return(()=>{})
    },[formData.messageType])

    // get parase
    useEffect(()=>{
        // console.log(contactsFromCSVImport)
        let contactsImported = contactsFromCSVImport&&JSON.parse(JSON.stringify(contactsFromCSVImport));     
        let contacts = '';
        contactsFromCSVImport&&contactsImported.map((data) => 
            contacts += data.data + ","
        )
        contacts = contacts.replace(/,\s*$/, "");
        contacts = contacts.replace(/,\s*$/, "");
        setImportContacts(contacts)
        // update recipient counter
        let contactsList = contactsFromCSVImport&&contacts.split(',')
        contactsFromCSVImport&&setRecipients(contactsList&&contactsList.length)
        // update cost
        setCost(((Math.ceil(chars/160)*senderIdCost)*recipients).toFixed(2))
    
    },[contactsFromCSVImport])

    
    const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value
        });
        if(e.target.name == 'contacts' ) {
            // update recipient counter
            let contacts = formData.contacts?.split(',')
            formData.contacts&&setRecipients(contacts&&contacts.length)
            // // update cost
            // setCost(((Math.ceil(chars/160)*senderIdCost)*recipients).toFixed(2))
         
        }
        if(e.target.name == 'message') {     
            // detect thaana
            // if(e.target.value.match(dhivehiChars)) { 
            //     setMainMessageType('Thaana')
            //     setChars(e.target.value.length * 2)
            //     setCalculatableCharacterLength(160)
            //     // if(e.target.value.length > 280) {
            //     //     setCalculatableCharacterLength(268)
            //     // } else {
            //     //     setCalculatableCharacterLength(280)
            //     // }
                
            // } else {
                setMainMessageType('Text')
                setChars(e.target.value.length)
                setCalculatableCharacterLength(160)
           //}     

        }
        if(e.target.name == 'senderId' && formData.senderId) {  
            let senderIdData = e.target.value.split('@')     
            setSenderIdSelected(senderIdData[0])
            setSenderIdCost(senderIdData[1])
        }
        // change templates
        if(e.target.name == 'templates') {
            setMessageBox('') 
            setFormData({
                ...formData,
                message: ''
            });
            setMessageBox(e.target.value)
            setChars(e.target.value.length)
            // // update cost
            // setCost(((Math.ceil(chars/calculatableCharacterLength)*senderIdCost)*recipients).toFixed(2))
        }

        
    };
    // update cost
    useEffect(()=>{
        // update cost
        setCost(((Math.ceil(chars/calculatableCharacterLength)*senderIdCost)*recipients).toFixed(2))
    },[chars,calculatableCharacterLength,recipients])

    const resetForm = (e) => {
        setFormData({
            // contacts: '',
            // importContacts:'',
            // groupContacts:'',
            // message:'',
        });
      };



  
    function toggle(tab) {
      if (activeTab !== tab) {
        setactiveTab(tab)
        // reset every thing
        // resetForm();
        formData.contacts = '';
        formData.importContacts = '';
        formData.groupContacts = '';
        formData.message = '';
        setRecipients(0);
        setChars('')
        setCost(0)
        setGroupContacts() 
        setImportContacts()
        setMessageBox('')
      }
    }

 
    const checkProgressRealTime = async (signature) => {
        const data = {'signature':signature}
        const progressIndicatorRaw = await checkProgress(data);
        const progressIndicator = await JSON.parse(JSON.stringify(progressIndicatorRaw));
        await setProgressNumber(progressIndicator.progress);
        return progressIndicator.progress;
    }
 
    const _getGroupContacts = async (e)=> {
        const groupId = {'groupId' : e.target.value};
        const response = await getGroupContacts(groupId);
        let contacts = '';
        response.map((data) => 
            contacts += data.contact + ","
        )
        contacts = contacts.replace(/,\s*$/, "");
        setGroupContacts(contacts);
        setGroupContactsReady(true)
        // update recipient counter
        let contactsList = contacts.split(',')
        setRecipients(contactsList&&contactsList.length)
        // update cost
        setCost(((Math.ceil(chars/calculatableCharacterLength)*senderIdCost)*recipients).toFixed(2))
       
     

    }


    const handleSubmit = async (event) => {   
        // update balance
        updateBalance();

        event.preventDefault();
        let message = formData.message ? formData.message : messageBox

        //validate
        if(recipients < 1) {
            // notify({ status: 'error', message: "Please enter atlease one recipient"});
            setAlertType('error');
            setAlertTitle('Recipient');
            setAlertDescription('Please enter atlease one recipient!');
            setShowAlert(true);
        } else if(message == '' || message == null || message == undefined) {
            setAlertType('error');
            setAlertTitle('Message');
            setAlertDescription('Please enter message!');
            setShowAlert(true);
        } else {
   
            // update progress
            setQueueProgressMessage('PREPARING')
            let data = null;
            if(activeTab == 1) {
                data = {'recipient':formData.contacts, 'message':message, 'messageType': formData.messageType, 'senderId' : formData.senderId.split('@')[0], 'scheduledDate' : formData.scheduledDate}
            } else if(activeTab == 2) {
                data = {'recipient':groupContacts, 'message':message, 'messageType': formData.messageType, 'senderId' : formData.senderId.split('@')[0], 'scheduledDate' : formData.scheduledDate}
            }  else if(activeTab == 3) {
                data = {'recipient':importContacts, 'message':message, 'messageType': formData.messageType, 'senderId' : formData.senderId.split('@')[0], 'scheduledDate' : formData.scheduledDate}
            } 
            // disable send button with progress loader
            setSubmitting(true);
            // send messsage to api
            const response = await sendNewMessageRequest(data);
            if((response?.type === "success")){
                // check if the message is scheduled
                if(scheduleMessage) {
                    // set message
                    setQueueProgressMessage("Message scheduled successfully!");
                    // send notification
                    notify({ status: 'success', message: response.message });
                    // show button
                    setSubmitting(false);
                } else {
                    // set message
                    setQueueProgressMessage("Request queued, you shall see progress momentarily!");
                    // send notification
                    notify({ status: 'success', message: response.message });
                    // get message signature for progress check
                    const signature = await JSON.parse(JSON.stringify(response));
                    let startInterval = setInterval(async () => { 
                        // fetch progress
                        let progress = await checkProgressRealTime(signature.signature);
                        if(progress > 99) {
                            // clear timer
                            clearInterval(startInterval);
                            // show button
                            setSubmitting(false);
                            // update balance
                            updateBalance();
                        }
                    }, 5000);  
                }
                 

            } else {
                setSubmitting(false);
                notify({ status: 'error', message: response.message })
            }
            
        }       
    };

    const updateBalance = async () => {
        // refresh balance on top
        let checkBalance  = await checkAuthRequest();
        checkBalance && checkBalance.Status !== 'error' ? setUserInfo({...userInfo, balance:checkBalance.user.balance }) : notify({ status: 'error', message: checkBalance.Message });
    
    }

  
 
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <h4>COMPOSE NEW MESSAGE</h4> */}
                    <Row>
                        <Col md={7} cx={12}>
                            <Card>
                                <CardBody>
                                <CardTitle className="mb-4">COMPOSE NEW MESSAGE</CardTitle>
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                            <Label for="senderId">Sender ID</Label>
                                            <select
                                                    className="form-control"
                                                    id="senderId"
                                                    name="senderId"
                                                    onChange={handleChange}
                                            >
                                               { senderIdsReady? 
                                                    senderIds.map((data)=>
                                                       <option value={data.sender_id + "@" + data.cost}>{data.sender_id + "@" + data.cost}</option>
                                                    )
                                                : null}
                                            </select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                            <Label for="formrow-firstname-Input">Process Type</Label>
                                                <select
                                                    className="form-control"
                                                    id="messageType"
                                                    name="messageType"
                                                    onChange={handleChange} 
                                                >
                                                <option value="instant">Instant</option>
                                                <option value="scheduled">Scheduled</option>
                                            </select>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                            <Label for="formrow-firstname-Input">Message Template</Label>
                                                <select
                                                    className="form-control"
                                                    id="templates"
                                                    name="templates"
                                                    onChange={handleChange} 
                                                >
                                              <option selected value={''}>-- CLEAR & SELECT --</option>
                                                {templatesReady? 
                                                    templates&&templates.map((data)=>
                                                    <option value={data.details}>{data.title}</option>
                                                ) :null}
                                            </select>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Nav tabs className="nav-tabs-custom">
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                        active: activeTab === "1",
                                                        })}
                                                        onClick={() => {
                                                        toggle("1")
                                                        }}
                                                    >
                                                        Numbers
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                        active: activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                        toggle("2")
                                                        }}
                                                    >
                                                        Groups
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                        active: activeTab === "3",
                                                        })}
                                                        onClick={() => {
                                                        toggle("3")
                                                        }}
                                                    >
                                                        File Import
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1" className="pt-3 pb-3">
                                                    <Row>
                                                        <Col sm="12">
                                                        <Label for="contacts" style={{color:'#2a9d8f'}}>Enter numbers of the recipients seperated by comma (,). Example: 7991363,7991364,7991365,..</Label>
                                                            <Input
                                                                type="textarea"
                                                                className="form-control"
                                                                id="contacts"
                                                                name="contacts"
                                                                rows={4} 
                                                                value={formData.contacts}
                                                                onChange={handleChange} 
                                                            />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="2" className="pt-3 pb-3">
                                                    <Row>
                                                        <Col sm="12">
                                                        {/* <Label for="numbers" style={{color:'#2a9d8f'}}>Enter numbers of the recipients seperated by comma (,). Example: 7991363,7991364,7991365,..</Label> */}
                                                            <FormGroup>
                                                                <Label for="formrow-firstname-Input">Select Group</Label>
                                                                    <select
                                                                        className="form-control"
                                                                        id="group"
                                                                        name="group"
                                                                        onChange={_getGroupContacts} 
                                                                    >
                                                                    <option selected disabled>-- SELECT --</option>
                                                                    { groupsReady? 
                                                                        groups.map((data)=>
                                                                            <option value={data.id}>{data.name}</option>
                                                                        )
                                                                    : null}
                                                                </select>
                                                            </FormGroup>
                                                            <Label for="groupContacts" style={{color:'#2a9d8f'}}>Associated group contacts.</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    id="groupContacts"
                                                                    name="groupContacts"
                                                                    rows={4} 
                                                                    value={groupContacts}
                                                                />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="3" className="pt-3 pb-3">
                                                    <Row>
                                                        <Col sm="12">
                                                            <CSVParse />
                                                                <Label for="importContacts" style={{color:'#2a9d8f'}}>Imported contacts.</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    id="importContacts"
                                                                    name="importContacts"
                                                                    rows={4} 
                                                                    value={importContacts}
                                                                     
                                                                />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>

                  
          
                                        </Col>
                                    </Row>    
                                    <Row>
                                        <Col sm="12">
                                        <Label for="message" style={{color:'#2a9d8f'}}>Message</Label>
                                            <Alert color="primary" style={{fontSize:'12px'}}>
                                                <strong>[PRCODE]</strong> = Random alphanumeric promotion code (8 characters).
                                            </Alert>
                                            <Input
                                                type="textarea"
                                                className="form-control"
                                                id="message"
                                                rows={6} 
                                                name="message"
                                                value={formData.message? formData.message : messageBox}
                                                // onKeyUp={handleChange} 
                                                onChange={handleChange} 
                                            />
                                        </Col>
                                    </Row> 
                                   <Row>
                                        <Col md="6" className="pt-3">
                                            {scheduleMessage?<div>
                                                <Label for="message" style={{color:'#2a9d8f'}}>Scheduled Date</Label>
                                                <Input
                                                    className="form-control"
                                                    id="scheduledDate"
                                                    placeholder="Date in DD-MM-YYY"
                                                    rows={6} 
                                                    type="datetime-local"
                                                    name="scheduledDate"
                                                    onChange={handleChange} 
                                                />
                                            </div>:null}
                                        </Col>
                                        <Col md="6" className="pt-3">
                                            <div style={{width:'100%', alignContent:'flex-end', paddingTop:'20px', display:'flex', flexDirection:'column', alignItems:'flex-end'}}>
                                                {!submitting?
                                                <button type="submit" className="btn btn-primary w-md">
                                                    Send
                                                </button>:<Spinner className="mr-2" color="primary" />}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div>
                                        <br/><br/>
                                    
                                    </div>
                                </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={5} cx={12}>
                            <Row>
                                <Col md={6} sm={6}>
                                    <div align="center" style={{width:'100%', display:'table', border:'2px solid #999', minHeight:'100%', height:'100%',justifyContent:'center', alignItems:'center', alignContent:'center', padding:'40px 15px', borderRadius:'10px', backgroundColor:'#d9d9d9'}}>
                                        <div style={{borderRadius:'5px', height:'8px', width:'50px',  marginTop:'-20px', marginBottom:'20px',  backgroundColor:'#666'}}>&nbsp;</div>
                                        <Scrollbars style={{ width:'100%',height:'100%',}}>
                                            <div style={{width:'100%', display:'table',  height:'100%', padding:'10px', backgroundColor:'rgba(255,255,255,0.8)'}}>
                                                    <div>
                                                        <div style={{display:'fixed', height:'30px',backgroundColor:'transparent',  fontWeight:'bold' }}>{senderIdSelected}</div>
                                                        {formData.message || messageBox ? 
                                                        mainMessageType == "Thaana" ?<div style={{backgroundColor:'rgba(85,110,230,1)', textAlign:'right', direction:'rtl', fontSize:'12px', padding:'10px', borderRadius:'5px', color:'#FFF'}}>
                                                            {formData.message? formData.message.replace(/\[PRCODE\]/g, PRCODE) : messageBox}
                                                        </div> : <div style={{backgroundColor:'rgba(85,110,230,1)', textAlign:'left', fontSize:'12px', padding:'10px', borderRadius:'5px', color:'#FFF'}}>
                                                            {formData.message? formData.message.replace(/\[PRCODE\]/g, PRCODE) : messageBox}
                                                        </div>: null}
                                                    </div>
                                            </div>
                                        </Scrollbars>
                                    </div>
                                </Col>
                                <Col md={6} sm={6}>
                                <Card style={{backgroundColor:'#383b53', minHeight:'100%', }}>
                                        <CardBody>
                                            <div>
                                                <CardTitle className="mb-0" style={{color:'#FFF'}}>MESSAGE TYPE</CardTitle>
                                                <CardTitle className="mb-4" style={{color:'#f5cb5c', fontSize:'1.5em'}}>{mainMessageType}</CardTitle>
                                            </div>
                                            <hr style={{borderTop:'1px solid #a8dadc'}} />
                                            <div>
                                                <CardTitle className="mb-0" style={{color:'#FFF'}}>RECIPIENTS</CardTitle>
                                                <CardTitle className="mb-4" style={{color:'#f5cb5c', fontSize:'1.5em'}}>{recipients}</CardTitle>
                                            </div>
                                            <hr style={{borderTop:'1px solid #a8dadc'}} />
                                            <div>
                                                <CardTitle className="mb-0" style={{color:'#FFF'}}>NUMBER OF CHARACTERS</CardTitle>
                                                <CardTitle className="mb-4" style={{color:'#f5cb5c', fontSize:'1.5em'}}>{chars?chars:0}</CardTitle>
                                            </div>
                                            <hr style={{borderTop:'1px solid #a8dadc'}} />
                                            <div>
                                                <CardTitle className="mb-0" style={{color:'#FFF'}}>NUMBER OF MESSAGES</CardTitle>
                                                <CardTitle className="mb-4" style={{color:'#f5cb5c', fontSize:'1.5em'}}>{Math.ceil(chars/calculatableCharacterLength)}</CardTitle>
                                            </div>
                                            <hr style={{borderTop:'1px solid #a8dadc'}} />
                                            <div>
                                                <CardTitle className="mb-0" style={{color:'#FFF'}}>ESTIMATED COST</CardTitle>
                                                <CardTitle className="mb-4" style={{color:'#f5cb5c', fontSize:'1.5em'}}>{cost}</CardTitle>
                                            </div>
                                            <hr style={{borderTop:'1px solid #a8dadc'}} />
                                            <div>
                                                <CardTitle className="mb-0" style={{color:'#FFF'}}>PROGRESS</CardTitle>
                                                <CardTitle className="mb-4" style={{color:'#f5cb5c', fontSize:'1.5em'}}>
                                                    {progressNumber > 2 && !scheduleMessage? <div>
                                                    <div className="animated-progess mb-30">
                                                        <Progress animated color="primary" style={{margin:'10px 0 10px 0'}} value={progressNumber.toFixed(0)}  />
                                                    </div>
                                                    {progressNumber>99?progressNumber.toFixed(0) + "% complete" : null}
                                                    </div> : <div>
                                                        <CardTitle className="mb-4" style={{color:'#f5cb5c', fontSize:'0.8em'}}>{queueProgressMessage?queueProgressMessage:'WAITING'}</CardTitle>
                                                    </div>}
                                                </CardTitle>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                        
                    {showAlert ? (
                        <SweetAlert
                        type={alertType}
                        title={alertTitle}
                        onConfirm={() => {
                           setShowAlert(false)
                        }}
                        >
                        {alertDescription}
                        </SweetAlert>
                    ) : null}
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default Compose;

