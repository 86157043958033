import axios from 'axios';
import configData from "../../config.json";
const download = axios.create ({
    baseURL: configData.API_URL,
    responseType: 'blob', // important
    timeout: 10000000000,
    headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
    maxContentLength: 100000000000000000,
    maxBodyLength: 100000000000000000
});

download.interceptors.request.use (
    function (config) {
        const token = localStorage.getItem('authToken');
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    function (error) {
        return Promise.reject (error);
    }
);

download.defaultCatch = (error) => ({"Status":'error',"Message":error.message, payload: error.response?.data})

export default download;

 

