import React, { Component } from 'react'

import { CSVReader } from 'react-papaparse'
import { Col, Modal, Row, Button, Label, Form, FormGroup, Input, Card, NavItem, NavLink, Progress, TabContent, TabPane, PopoverHeader, PopoverBody, Container, } from 'reactstrap';
import classnames from 'classnames'
import {atom, useRecoilState} from 'recoil'

const buttonRef = React.createRef()

export const CSVImportState = atom({
    key: 'contactsFromCSVImport',
    default:[]
})

const  CSVParse = () => {

  const [contactsFromCSVImport, setContactsFromCSVImport] = useRecoilState(CSVImportState)

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }

 

 const handleOnFileLoad = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
    
    setContactsFromCSVImport([data])
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------')
    // console.log(data)
    setContactsFromCSVImport('')
    console.log('---------------------------')
  }

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }
    return (
      <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          <Container
            style={{
            //   display: 'flex',
            //   flexDirection: 'row',
              width:'100%'
            //   marginBottom: 10
            }}
          >
              <Row>
                  <Col md={9}>
                    <button type="submit" 
                            className="btn btn-primary w-md"
                            onClick={handleOpenDialog}>                  
                            Browser
                    </button>
                    <div
                    style={{
                        height: 55,
                        lineHeight: 1.5,
                        marginTop: 5,
                        marginBottom: 5,
                        paddingLeft: 13,
                        paddingTop: 3,
                        width: '100%',
                        maxWidth:'100%',
                        minWidth:'100%',
                        fontStyle:'italic',
                        color:'#666'
                        // fontSize:'12px',
      
                    }}
                    > Note : File must be comma delimited CSV format file. <a href="/SampleContactList.csv">Download sample file</a>.
                    {/* {file && file.name} */}
                    </div>
                  </Col>
                  <Col md={3} align="right">                       
                    <buttion
                        className="btn btn-danger w-md"
                        style={{width:'5px', minWidth:'70px',}}
                        onClick={handleRemoveFile}
                    >
                    Clear
                    </buttion>
                  </Col>
              </Row>
             
            
           
          
          </Container>
        )}
      </CSVReader>
    )
  
}

export default CSVParse;