import React from "react";

import {Link} from "react-router-dom";
// Import menuDropdown
// eslint-disable-next-line
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light-full.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";
// import logoDark from "../../assets/images/logo-dark.png";

// import { withNamespaces } from 'react-i18next/withNamespaces';
import { useRecoilValue } from "recoil";
import { notificationSettingsState, condensedMenuEnabledState } from "../../state/GlobalState";
import { notificationState } from "../../api/state/NotificationState";
import { userState } from "../../api/state/AuthState";

const Header = props => {

  const notificationSettings = useRecoilValue(notificationSettingsState)
  const notifications = useRecoilValue(notificationState)
  const condensedMenuEnabled = useRecoilValue(condensedMenuEnabledState)
  const user = useRecoilValue(userState)

  const toggleMenu = () => {
    if(condensedMenuEnabled){
      props.toggleMenuCallback();
    }
  }

  /*UNCOMMENTRIGHTBAR
  const toggleRightbar = () =>{
    props.toggleRightSidebar();
  }
  UNCOMMENTRIGHTBAR*/


  const toggleFullscreen = () => {
    if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="35" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="30" />
                  </span>
                </Link>
              </div>
              {condensedMenuEnabled ? (
                <button type="button" onClick={toggleMenu} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                  <i className="fa fa-fw fa-bars"></i>
                </button>
              ) : null}

            </div>
            
            <div className="d-flex">

              {/* <LanguageDropdown /> */}
              <div className="dropdown d-none d-lg-inline-block ml-1 mr-2 ml-2" style={{justifyContent:'center', display:'flex', flexDirection:'column', height:'70px',}}>
                  <div style={{justifyContent:'center', display:'flex', flexDirection:'column',height:'70px', fontSize:'14px'}}>
                    <span> <strong>{ user.balance > 0 ? ("Balance : "+JSON.stringify(user.balance)) : "Postpaid User"}</strong></span>
                  </div>
              </div> 
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button type="button" onClick={toggleFullscreen} className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                  <i className="bx bx-fullscreen"></i>
                </button>                
              </div>                          
              {notificationSettings?.enabled ? <NotificationDropdown notifications={notifications} settings={notificationSettings} /> : null}
              <ProfileMenu user={user} />

              {/*UNCOMMENTRIGHTBAR <div onClick={toggleRightbar} className="dropdown d-inline-block">
                <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> UNCOMMENTRIGHTBAR*/}

            </div>
          </div>
        </header>
      </React.Fragment>
  );
}

export default (Header);
