
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/SenderidController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const senderidListState = atom({
    key: 'senderidListState',
    default: [],
})

export const modalConfigDefault = { size: "md", wizard: false }

export const filterConfigDefault = {
    filter: false,
}

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const userListState = atom({
    key: 'userListState',
    default: [],
})

export const senderidDataStructureState = selector({
    key: 'senderidDataStructureState',
    get:({ get }) => (
        [           
            { 
                label: "User",
                type: "select",
                order: { table: 0, form: 1 },
                permission : 'create-client',
                list : get(userListState),
                create: [{ enabled: true, size: 12 }], update: [{ enabled: false, size: 4 }], view: ['same_as:create.0'],
            },{ 
                label: "User",
                type: "text",
                field:"user.name",
                order: { table: 1, form: 0},
                permission : 'create-client',
                create: [{ enabled: false, size: 12 }], update: [{ enabled: false, size: 4 }], view: ['same_as:create.0'],
            },{ 
                label: "id",
                type: "text",
                order: { table: 0, form: 0},
                create: [{ enabled: false, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },
            { 
                label: "Sender ID",
                type: "text",
                field:'sender_id',
                order: { table: 2, form: 2},
                create: [{ enabled: true, size: 12,attributes: {maxLength : 11},subText: 'max 11 alphanumeric characters' }], update: [{ enabled: true, size: 12,attributes: {maxLength : 11},subText: 'max 11 alphanumeric characters' }], view: [ 'same_as:create.0' ],
            },
            { 
                label: "Cost",
                type: "text",
                field:'cost',
                order: { table: 3, form: 3},
                create: [{ enabled: false, size: 12 }], update: [{enabled: true, size: 12}], view: [ 'same_as:create.0' ],
            },
            { 
                label: "Status",
                type: "text",
                field: "approved",
                order: { table: 4, form: 44444},
                create: [{ enabled: false, size: 12 }], update: [ 'same_as:create.0' ], view: [ 'same_as:create.0' ],
            },
            { 
                label: "Approved",
                type: "switch",
                field:"approvedSwitch",
                order: { table: 0, form: 5},
                create: [{ enabled: false, size: 12 }], update: [{enabled: true, size: 12}], view: [{enabled: false, size: 12}],
            },
        ]
    ).filter((v)=>{
        const page = get(pageState)
        const permissions = get(permissionState)
        if(! v.permission){
            return true
        }
        let absolute = false;
        if(v.permission.substr(0,1) === ":"){
            absolute = true;
            v.permission = v.permission.substr(1)
        }
        v.permission = absolute ? v.permission : v.permission + "-" + page
        return permissions?.includes(v.permission)
    })
})







