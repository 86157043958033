
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/TopupController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const topupListState = atom({
    key: 'topupListState',
    default: [],
})

export const modalConfigDefault = { size: "md", wizard: false }

export const filterConfigDefault = {
    filter: false,
}

export const userListState = atom({
    key: 'userListState',
    default: [],
})


export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const topupDataStructureState = selector({
    key: 'topupDataStructureState',
    get:({ get }) => (
        [
          { 
                label: "User",
                type: "select",
                order: { table: 0, form: 1 },
                permission : 'create-client',
                list : get(userListState),
                create: [{ enabled: true, size: 12 }], update: [{ enabled: false, size: 4 }], view: ['same_as:create.0'],
            },{ 
                label: "User",
                type: "text",
                field:"user.name",
                order: { table: 1, form: 0},
                permission : 'create-client',
                create: [{ enabled: false, size: 12 }], update: [{ enabled: false, size: 4 }], view: ['same_as:create.0'],
            },{ 
                label: "Description",
                type: "text",
                field: 'name',
                order: { table: 2, form: 2 },
                create: [{ enabled: true, size: 6 }], update: [{ enabled: false, size: 6 }], view: [ 'same_as:create.0' ],
            },
            { 
                label: "Credit",
                type: "text",
                field: 'credit',
                order: { table: 3, form: 3 },
                create: [{ enabled: true, size: 6 }], update: [{ enabled: false, size: 6 }], view: [ 'same_as:create.0' ],
            },
            { 
                label: "Payment Status",
                type: "text",
                field: 'payment_status',
                order: { table: 4, form: 0 },
                create: [{ enabled: false, size: 4 }], update: [{ enabled: false, size: 4 }], view: [{ enabled: false, size: 4 } ],
            },
            { 
                label: "Payment",
                type: "select",
                field: 'payment_status',
                list: [{label: "PAID", value: "PAID" },{label: "UNPAID", value: "UNPAID" }],
                order: { table: 0, form: 5 },
                create: [{ enabled: false, size: 12 }], update: [{ enabled: true, size: 12 }], view: [ 'same_as:create.0' ],
            },
            { 
                label: "Approved",
                type: "switch",
                field:"approved_status",
                order: { table: 5, form: 6 },
                create: [{ enabled: false, size: 12 }], update: [{ enabled: true, size: 12 }], view: [ 'same_as:create.0' ],
            },
        ]
    ).filter((v)=>{
        const page = get(pageState)
        const permissions = get(permissionState)
        if(! v.permission){
            return true
        }
        let absolute = false;
        if(v.permission.substr(0,1) === ":"){
            absolute = true;
            v.permission = v.permission.substr(1)
        }
        v.permission = absolute ? v.permission : v.permission + "-" + page
        return permissions?.includes(v.permission)
    })
    
})

