
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/PermissionController";

export const permissionListState = atom({
    key: 'permissionListState',
    default: [],
})

export const modalConfigDefault = { size: "sm", wizard: false }

export const filterConfigDefault = {
    filter: false,
}

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const permissionDataStructureState = selector({
    key: 'permissionDataStructureState',
    get: () => (
        [{ 
            label: "Name",
            type: "text",
            order: { table: 1, form: 1 },
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{ 
            label: "Guard Name",
            type: "select",
            rootValue: true,
            order: { table: 2, form: 2 },
            create: [{ enabled: true, size: 12, default: "web" }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: [
                { label: "web", value: "web" }
            ]
        }]
    )
})