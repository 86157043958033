import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from "react-router-dom";
// import logo from './logo.svg';
// import './App.css';
import "./assets/scss/theme.scss";
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import { authState, permissionState, userState, tokenState, tokenRefreshIntervalState } from './api/state/AuthState';
import { navigationState } from './state/NavigationState';
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { checkAuthRequest, tokenRefreshRequest } from "./api/controller/AuthController";
import { preloaderState, tokenNeedsRefreshState } from "./state/GlobalState";
import moment from "moment";
import Pages404 from "./app/404";

const App = () => {

  const [isAuth, setAuth] = useRecoilState(authState)
  const resetAuth = useResetRecoilState(authState)
  const setUser = useSetRecoilState(userState)
  const resetUser = useResetRecoilState(userState)
  const setToken = useSetRecoilState(tokenState)
  const resetToken = useResetRecoilState(tokenState)
  const setPermissions = useSetRecoilState(permissionState)
  const resetPermissions = useResetRecoilState(permissionState)
  const navigation = useRecoilValue(navigationState)
  const [preloader, setPreloader] = useRecoilState(preloaderState)
  const [tokenNeedsRefresh, setTokenNeedsRefresh] = useRecoilState(tokenNeedsRefreshState)
  const tokenRefreshInterval = useRecoilValue(tokenRefreshIntervalState)
  const [expiryTimer, setExpiryTimer] = useState(null)
  const history = useHistory()

  const resetAuthStates = useMemo(() => () => {
    resetUser()
    resetToken()
    resetPermissions()
    resetAuth()
  }, [resetUser, resetAuth, resetPermissions, resetToken])

  const createImageFromInitials = (size, name, color) => {
    if (name == null) return;
    let temp = ''
    name.trim().split(" ").forEach((v)=>{temp+=v[0]})
    name = temp.toUpperCase().substr(0,2)
    const canvas=document.createElement('canvas')
    const context=canvas.getContext('2d')
    canvas.width=canvas.height=size

    context.fillStyle="#ffffff"
    context.fillRect(0,0,size,size)

    context.fillStyle=`${color}50`
    context.fillRect(0,0,size,size)

    context.fillStyle=color;
    context.textBaseline='middle'
    context.textAlign='center'
    context.font =`${size/2}px Roboto`
    context.fillText(name,(size/2),(size/2))

    return canvas.toDataURL()
  };

  const handleAuthResponse = useMemo(() => (response, token=null, rememberMe=false) => {
    if(response.decodedToken){
      const tokenExpiry = moment(response.decodedToken.created_at).add(tokenRefreshInterval, 'minutes');
      if(tokenExpiry.isAfter(moment())){
        const user = {...response.user, avatar: response.user.avatar || createImageFromInitials(120, response.user.name, "#556ee6")}
        setUser(user)
        setAuth(true)
        setPermissions(response.decodedToken.abilities || [])
        setToken({token: token, expiry: tokenExpiry})
        setTokenNeedsRefresh(false)
        const ttl = tokenExpiry.diff(moment())
        clearTimeout(expiryTimer)
        setExpiryTimer(setTimeout(()=>{
          setTokenNeedsRefresh(true)
        }, ttl))
        localStorage.setItem('authToken', token)
        if(localStorage.getItem('authUser') || rememberMe){
          localStorage.setItem('authUser', JSON.stringify(user))
        }
      } else {
        localStorage.removeItem('authToken')
      }
    } else {
      resetAuthStates()
      localStorage.removeItem('authToken')
      history.push('/login');
    }
    // eslint-disable-next-line
  }, [setUser, setAuth, setPermissions, setToken, setExpiryTimer, setTokenNeedsRefresh, expiryTimer, resetAuthStates, tokenRefreshInterval]);

  useEffect(() => {
    const token = localStorage.getItem('authToken')
    async function checkAuth(){
      if(token && !isAuth){
        const response = await checkAuthRequest()
        handleAuthResponse(response, token)
      }
      setPreloader(false)
    }
    checkAuth()
  }, [handleAuthResponse, isAuth, setPreloader])
  
  useEffect(() => {
    if(tokenNeedsRefresh) {
      async function refreshToken(){
        const response = await tokenRefreshRequest()
        handleAuthResponse(response, response.encodedToken)
      }
      refreshToken();
    }
  }, [tokenNeedsRefresh, handleAuthResponse])

  const renderRoute = ({route, index}) => {
    const props = route.link === "/login" ? {handleAuthResponse: handleAuthResponse} : null;
    return (
      <Route key={index} path={route.link}>
        {route.middleware === "guest" ? <NonAuthLayout>{React.cloneElement(route.component, props ? {...props} : null)}</NonAuthLayout> : <VerticalLayout>{React.cloneElement(route.component, props ? {...props} : null)}</VerticalLayout>}
      </Route>
    )
  }

  const mappedRoutes = () => {
    return navigation.map((header)=>{
      return header.children?.map((item,idx)=>{
        return (
          item.children.length > 0 ? (
            item.children.map((child,childIdx)=>(
              renderRoute({route: child, index: idx+"-"+childIdx})
            ))
          ) : (
            renderRoute({route: item, index: idx})
          )
        )
      })
    })
  }

  return (
    <React.Fragment>
      {preloader ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
      ) : (
        <Router>
          <Switch>
            {mappedRoutes()}
            {/* Redirect / route to proper page */}
            <Route exact path="/"><Redirect to={isAuth ? "/home" : "/login"}  /></Route>
            <Route path="/404"><Pages404 /></Route>
            {/* Fallback Route (Default Route) */}
            <Redirect to="/" />

          </Switch>
        </Router>
      )}
      
    </React.Fragment>
  );
}

export default App;
