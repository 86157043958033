import React, {useEffect, useState} from "react";

import { withRouter } from "react-router-dom";
import config from "../../config.json"


// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
// import Footer from "./Footer";
/*UNCOMMENTRIGHTBAR import Rightbar from "../CommonForBoth/Rightbar"; UNCOMMENTRIGHTBAR*/

import {
  changeLeftSidebarTheme,
  changeLeftSidebarType,
  changeTopbarTheme,
  /*UNCOMMENTRIGHTBAR toggleRightSidebar, UNCOMMENTRIGHTBAR*/
} from "../../helpers/layout";
import { leftSidebarTypeState } from "../../state/GlobalState";
import { useRecoilState } from "recoil";




const Layout = props => {

  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  // let topbarTheme = 'light';
  // let leftSideBarTheme = 'dark'
  const [leftSidebarType, setLeftSidebarType] = useRecoilState(leftSidebarTypeState);
  /*UNCOMMENTRIGHTBAR let showRightSidebar = false;

  const _toggleRightSidebar = () => {
    toggleRightSidebar();
    showRightSidebar = !showRightSidebar;
  }
  UNCOMMENTRIGHTBAR*/

  const capitalizeFirstLetter = string => {
    string = string.charAt(0).toUpperCase() + string.slice(1);
    let temp = ""
    temp = string.split("-").map((val)=>{
        return val.charAt(0).toUpperCase() + val.slice(1);
    })
    return temp.join(" ").trim()
  };

  useEffect(() => {

    // Scroll Top to 0
    window.scrollTo(0, 0);

    let pathSplit = props.location.pathname.split("/")
    pathSplit = pathSplit.filter((val)=>(val !== null && val !== ""))
    let path = pathSplit[pathSplit.length-1]

    document.title = path ? capitalizeFirstLetter(path)+" | "+config.APP_NAME : config.APP_NAME; // CHANGE

    // if (this.props.layoutWidth) {
    //   this.props.changeLayoutWidth(this.props.layoutWidth);
    // }
    //
    // if (showRightSidebar) {
    //   toggleRightSidebar();
    // }
  }, [props.location.pathname])

  useEffect(() => {
    changeLeftSidebarTheme('dark')
    changeTopbarTheme('light');

    if (leftSidebarType) {
      changeLeftSidebarType(leftSidebarType, isMobile)
    }
  }, [leftSidebarType, isMobile])


  const toggleMenuCallback = () => {
    if (leftSidebarType === "default") {
      setLeftSidebarType("condensed");
      changeLeftSidebarType("condensed", isMobile)
    } else if (leftSidebarType === "condensed") {
      setLeftSidebarType("default");
      changeLeftSidebarType("default", isMobile)
    }
  };

  return (
      <React.Fragment>


        <div id="layout-wrapper">
          <Header toggleMenuCallback={toggleMenuCallback}
                  //UNCOMMENTRIGHTBAR toggleRightSidebar={_toggleRightSidebar}
          />
          <Sidebar theme={'dark'}
                   type={leftSidebarType}
                   isMobile={isMobile} />
          <div className="main-content">
            {props.children}
          </div>
          {/*<Footer />*/}
        </div>
        {/*UNCOMMENTRIGHTBAR <Rightbar /> UNCOMMENTRIGHTBAR*/}
      </React.Fragment>
  );
}

export default withRouter(Layout);
