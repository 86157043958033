import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import config from "../config.json"

class NonAuthLayout extends Component {
    constructor(props) {
        super(props);
        this.state={};
        this.capitalizeFirstLetter.bind(this);
    }
    
    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
    };
    removeHyphens = string => {
        let temp = ""
        temp = string.split("-").map((val)=>{
            return val.charAt(0).toUpperCase() + val.slice(1);
        })
        return temp.join(" ").trim()
    };

    componentDidMount(){
        let currentage = this.capitalizeFirstLetter(this.props.location.pathname);
        currentage = this.removeHyphens(currentage)

        document.title =
          currentage + " | " + config.APP_NAME;
    }
    render() {
        return <React.Fragment>
            {this.props.children}
        </React.Fragment>;
    }
}

export default (withRouter(NonAuthLayout));