import { cloneDeep } from "lodash";
import { atom, selector } from "recoil";
import { authState, permissionState } from "../api/state/AuthState";
import Login from "../app/guest/Login";
import ForgotPassword from "../app/guest/ForgotPassword";
import ChangePassword from "../app/guest/ChangePassword";
import Home from "../app/auth/home/index";
import Users from "../app/auth/users"
import Roles from "../app/auth/roles"
import Permissions from "../app/auth/permissions"
/*EXAMPLEBEGIN*/
import Profiles from "../app/auth/profiles"
/*EXAMPLEEND*/

import Compose from '../app/auth/messages/compose'
import Templates from "../app/auth/templates/index";
import Groups from '../app/auth/groups/index'
import Contacts from '../app/auth/contacts/index'
import Schedule from '../app/auth/schedules/index'
import QueueHistory from '../app/auth/histories/queue/index'
import MessageHistory from '../app/auth/histories/message/index'
import Senderids from '../app/auth/senderids/index'
import Topups from '../app/auth/topups/index'


let navigation = [
    {
        name: 'Menu',
        middleware: 'auth',
        permission: null,
        children: [
            {
                name: 'Login',
                icon: 'bx bx-lock',
                link: '/login',
                visible: true,
                component: <Login />,
                middleware: 'guest',
                permission: null,
                children: [],
            },
            {
                name: 'Forgot Password',
                icon: 'bx bx-lock',
                link: '/forgot-password',
                visible: true,
                component: <ForgotPassword />,
                middleware: 'guest',
                permission: null,
                children: [],
            },
            {
                name: 'Change Password',
                icon: 'bx bx-lock',
                link: '/change-password',
                visible: true,
                component: <ChangePassword />,
                middleware: 'guest',
                permission: null,
                children: [],
            },
            {
                name: 'Dashboard',
                icon: 'mdi mdi-monitor-dashboard',
                link: '/home',
                visible: true,
                component: <Home />,
                middleware: 'auth',
                permission: null,
                children: [],
            },
            {
                name: 'Message',
                icon: 'far fa-comment-dots',
                link: '/',
                visible: true,
                component: <Compose />,
                middleware: 'auth',
                permission: null,
                children: [
                    {
                        name: 'Compose',
                        icon: 'far fa-comment-dots',
                        link: '/compose',
                        visible: true,
                        component: <Compose />,
                        middleware: 'auth',
                        permission: null,
                        children: [],
                    },
                    {
                        name: 'Templates',
                        icon: 'far fa-comment-dots',
                        link: '/templates',
                        visible: true,
                        component: <Templates />,
                        middleware: 'auth',
                        permission: null,
                        children: [],
                    },
                ],
            },
            {
                name: 'Contacts',
                icon: 'far fa-address-card',
                link: '/',
                visible: true,
                component: <Profiles />,
                middleware: 'auth',
                permission: null,
                children: [
                    {
                        name: 'Groups',
                        icon: 'far fa-address-card',
                        link: '/groups',
                        visible: true,
                        component: <Groups />,
                        middleware: 'auth',
                        permission: null,
                        children: [],
                    },
                    {
                        name: 'Contacts',
                        icon: 'mdi mdi-account-plus',
                        link: '/contacts',
                        visible: true,
                        component: <Contacts />,
                        middleware: 'auth',
                        permission: null,
                        children: [],
                    },
                ],
            },
            {
                name: 'Schedule',
                icon: 'far fa-clock',
                link: '/schedule',
                visible: true,
                component: <Schedule />,
                middleware: 'auth',
                permission: null,
                children: [],
            },
            {
                name: 'History',
                icon: 'fa fa-history',
                link: '/history',
                visible: true,
                component: <QueueHistory />,
                middleware: 'auth',
                permission: null,
                children: [ 
                    {
                        name: 'Queue History',
                        icon: 'mdi mdi-file-chart',
                        link: '/queue',
                        visible: true,
                        component: <QueueHistory />,
                        middleware: 'auth',
                        permission: null,
                        children: [],
                    },{
                        name: 'Message History',
                        icon: 'mdi mdi-file-chart',
                        link: '/messages',
                        visible: true,
                        component: <MessageHistory />,
                        middleware: 'auth',
                        permission: null,
                        children: [],
                    },
                ],
            },
            {
                name: 'Senders IDs',
                icon: 'fa fa-id-badge',
                link: '/senderid',
                visible: true,
                component: <Senderids />,
                middleware: 'auth',
                permission: null,
                children: [],
            },
            {
                name: 'Topups',
                icon: 'fa fa-dollar-sign',
                link: '/topups',
                visible: true,
                component: <Topups />,
                middleware: 'auth',
                permission: null,
                children: [],
            },
            // {
            //     name: 'Reports',
            //     icon: 'mdi mdi-file-chart',
            //     link: '/',
            //     visible: true,
            //     component: <Profiles />,
            //     middleware: 'auth',
            //     permission: null,
            //     children: [
            //         {
            //             name: 'Daily Usage',
            //             icon: 'mdi mdi-file-chart',
            //             link: '/historyq',
            //             visible: true,
            //             component: <Profiles />,
            //             middleware: 'auth',
            //             permission: null,
            //             children: [],
            //         },
            //         {
            //             name: 'Schedule',
            //             icon: 'mdi mdi-file-chart',
            //             link: '/historyq',
            //             visible: true,
            //             component: <Schedule />,
            //             middleware: 'auth',
            //             permission: null,
            //             children: [],
            //         },
            //         {
            //             name: 'Topup',
            //             icon: 'mdi mdi-file-chart',
            //             link: '/historyq',
            //             visible: true,
            //             component: <Profiles />,
            //             middleware: 'auth',
            //             permission: null,
            //             children: [],
            //         },
            //         {
            //             name: 'Full Report',
            //             icon: 'mdi mdi-file-chart',
            //             link: '/historyq',
            //             visible: true,
            //             component: <Profiles />,
            //             middleware: 'auth',
            //             permission: null,
            //             children: [],
            //         },
            //     ],
            // },
            /*EXAMPLEBEGIN*/
            // {
            //     name: 'Profile',
            //     icon: 'mdi mdi-account',
            //     link: '/profile',
            //     component: <Profiles />,
            //     middleware: 'auth',
            //     permission: null,
            //     children: [],
            // },
            /*EXAMPLEEND*/
        ],
    },
    {
        name: 'ACL',
        children: [
            {
                name: 'Users',
                icon: 'fa fa-users',
                link: '/acl/users',
                visible: true,
                component: <Users />,
                middleware: 'auth',
                permission: 'read-users',
                children: []
            },
            {
                name: 'Roles',
                icon: 'fa fa-user-md',
                link: '/acl/roles',
                visible: true,
                component: <Roles />,
                middleware: 'auth',
                permission: 'read-roles',
                children: []
            },
            {
                name: 'Permissions',
                icon: 'fa fa-user-lock',
                link: '/acl/permissions',
                visible: true,
                component: <Permissions />,
                middleware: 'auth',
                permission: 'read-permissions',
                children: []
            },
        ]
    },
];

const rawNavigationState = atom({
    key: 'rawNavigationState',
    default: navigation,
});

export const navigationState = selector({
    key: 'navigationState',
    get: ({ get }) => {
        let tempNavigation = cloneDeep(get(rawNavigationState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        // Remove Nav Items based on auth and permissions
        tempNavigation = tempNavigation.map((header)=>{
            header.children = header.children.map((item)=>{
                item.children = item.children?.filter(item=>((isAuth && item.middleware === "auth" && (item.permission === null || permissions.includes(item.permission))) || (!isAuth && item.middleware === "guest")))
                return item
            })
            header.children = header.children?.filter(item=>(((isAuth && item.middleware === "auth" && (item.permission === null || permissions.includes(item.permission))) || (!isAuth && item.middleware === "guest")) && (item.component || item.children.length > 0)))
            return header
        });
        // Remove Empty headers
        tempNavigation = tempNavigation.filter(header=>(header.children.length > 0))
        return tempNavigation;
    }
})

