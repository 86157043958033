
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../../api/controller/HistoryController";

export const historyListState = atom({
    key: 'historyListState',
    default: [],
})

export const modalConfigDefault = { size: "md", wizard: false }

export const filterConfigDefault = {
    filter: false,
}

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const queueDataStructureState = selector({
    key: 'queueDataStructureState',
    get: () => (
        [
            { 
                label: "id",
                type: "text",
                order: { table: 0, form: 0},
                create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },{ 
                label: "Recipient",
                type: "text",
                order: { table: 0, form: 1},
                create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },{ 
                label: "Signature",
                type: "text",
                order: { table: 1, form: 1},
                create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },{ 
                label: "Process Type",
                type: "text",
                order: { table: 2, form: 2 },
                create: [{ enabled: false, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },{ 
                label: "Schedule Datetime",
                type: "text",
                order: { table: 3, form: 3 },
                create: [{ enabled: false, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },{ 
                label: "Sender ID",
                type: "text",
                order: { table: 4, form: 4 },
                create: [{ enabled: false, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },{ 
                label: "Message",
                type: "text",
                field: "message_text",
                order: { table: 5, form: 5 },
                create: [{ enabled: false, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },{ 
                label: "No. of Messages",
                type: "text",
                field:'noOfMessagesProcessed',
                order: { table: 6, form:6 },
                create: [{ enabled: false, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },{ 
                label: "Processed",
                type: "text",
                order: { table: 8, form: 8 },
                create: [{ enabled: false, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            },
        ]
    )
})

//signature','message as message_text','process_type','schedule_datetime','sender_id','processed





