
import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/RoleController";
import { permissionListState } from "../permissions/state";

export const roleListState = atom({
    key: 'roleListState',
    default: [],
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filterConfigDefault = {
    filter: false,
}

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const roleDataStructureState = selector({
    key: 'roleDataStructureState',
    get: ({ get }) => (
        [{ 
            label: "Name",
            type: "text",
            order: { table: 1, form: 1 },
            create: [{ enabled: true, size: 6 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{ 
            label: "Guard Name",
            type: "select",
            rootValue: true,
            order: { table: 2, form: 2 },
            create: [{ enabled: true, size: 6, default: "web" }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: [
                { label: "web", value: "web" }
            ]
        },{
            label: "Permissions",
            subFields: { label: "name", value: "id" },
            type: "multiselect",
            order: { form: 3 },
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: get(permissionListState),
        }]
    )
})