
import http from "../core/http";
import axios from 'axios';
import configData from "../../config.json";
import download from "../core/download";
const group = 'group';

export const getGroupContacts =  async (data) => {
    return await http.post(`${group}/getGroupContacts`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getGroups =  async (data) => {
    return await http.post(`${group}/getGroups`, data).then(res => res.data).catch(http.defaultCatch);
}
export const getSenderIds =  async (data) => {
    return await http.post(`${group}/getSenderIds`, data).then(res => res.data).catch(http.defaultCatch);
}


export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const downloadContact =  async (data) => {
    return await http.post(`${group}/download`, data).then((response) => {
            const fileName = data.name.replace(/[^a-zA-Z0-9 ]/g, '') + ".csv"
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
    });
}



export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}


 