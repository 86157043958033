import React, { useEffect,useState } from "react";
import { Container, Row } from "reactstrap"

//import component
import CardUser from "./CardUser"
import Settings from "./Settings"
import Posts from "./Posts"
import Comments from "./Comments"
import TapVisitors from "./TapVisitors"
import Activity from "./Activity"
import PopularPost from "./PopularPost"
import notify from '../../../custom/helpers/Notify';
import {getHomeStats} from '../../../api/controller/HomeController'
import { homeStatState } from "./state";
import { useRecoilState } from "recoil"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


const Home = props => {
  const [homeStat, setHomeStat] = useRecoilState(homeStatState);
  const [sentDataMonths, setSentDataMonths] = useState([])
  const [sentDataSuccess, setSentDataSuccess] = useState([])
  const [sentDataFailed, setSentDataFailed] = useState([])
  const [sentDataReady, setSentDataReady] = useState(false)


  useEffect(() => {
    async function fetchData(){
      const response = await getHomeStats();
      response && response.Status !== 'error' ? setHomeStat(response.data) : notify({ status: 'error', message: response.Message });

    }
    fetchData()
        
  }, []);


  useEffect(() => {
    async function fetchData(){
        // extract graph data
        let months = []
        let sentSuccess = []
        let sentFailed = []
        homeStat.map((data)=>{
          data.messageMonthWiseSuccess?.map((data)=>{
            sentSuccess.push(data.count)
          })
          data.messageMonthWiseFailed?.map((data)=>{
            sentFailed.push(data.count)
          })
          data.messageMonthWiseSuccess?.map((data)=>{
            months.push(data.month)
            // console.log(JSON.stringify(data));

          })
          setSentDataSuccess(sentSuccess)   
          setSentDataFailed(sentFailed)    
          setSentDataMonths(months)     
          setSentDataReady(true)          
        })
      }
    fetchData()    
    
  }, [homeStat]);



  const series = [
    {
      name: "Sent",
      // data: [18, 21, 45, 36, 65, 47, 51, 32, 40, 28, 31, 26],
      data: sentDataSuccess,
    },
    {
      name: "Failed",
      data: sentDataFailed,
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: ["#556ee6", "#faa307"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: sentDataMonths,
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            {/* card user */}
            {sentDataSuccess?<CardUser data={homeStat} options={options} series={series} />:null}
            {/* <Settings data={homeStat} /> */}
          </Row>       
        </Container>
      
      </div>
    </React.Fragment>
  )
}

export default Home
