import React, {Component} from "react";
// MetisMenu
import MetisMenu from "metismenujs";
import {Link, withRouter} from "react-router-dom";
//i18n
// import {withNamespaces} from 'react-i18next/withNamespace';

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
        this.setActive()
    }

    initMenu() {
        new MetisMenu("#side-menu");
        this.setActive()
    }

    setActive = () => {
        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
            } else {
                items[i].classList.remove("active")
                const parent = items[i].parentElement;

                if (parent) {
                    parent.classList.remove("mm-active");
                    const parent2 = parent.parentElement;

                    if (parent2) {
                        parent2.classList.remove("mm-show");

                        const parent3 = parent2.parentElement;

                        if (parent3) {
                            parent3.classList.remove("mm-active"); // li
                            parent3.childNodes[0].classList.remove("mm-active"); //a
                            const parent4 = parent3.parentElement;
                            if (parent4) {
                                parent4.classList.remove("mm-active");
                            }
                        }
                    }
                }
            }
        }
        if (matchingMenuItem) {
            matchingMenuItem.classList.add("active");
            const parent = matchingMenuItem.parentElement;

            if (parent) {
                parent.classList.add("mm-active");
                const parent2 = parent.parentElement;

                if (parent2) {
                    parent2.classList.add("mm-show");

                    const parent3 = parent2.parentElement;

                    if (parent3) {
                        parent3.classList.add("mm-active"); // li
                        parent3.childNodes[0].classList.add("mm-active"); //a
                        const parent4 = parent3.parentElement;
                        if (parent4) {
                            parent4.classList.add("mm-active");
                        }
                    }
                }
                return false;
            }
            return false;
        }
    }

        

    menuStack = module => {
        return (
            <React.Fragment key={module.name}>
                <li className="menu-title">{module.name}</li>
                {
                    module?.children?.map((menu, index) => {
                       return (
                           <li key={menu?.name + index.toString()}>
                               <Link to={menu.link || "/#"} className={`waves-effect ${menu.children.length ? 'has-arrow' : ''}`}>
                                   <i className={menu.icon}/>
                                   { menu?.visible&& <span>{menu.name}</span> }
                               </Link>
                               {menu?.children?.length ? (
                                    <ul className="sub-menu">
                                        {menu.children.map((child, index) => (
                                            child?.visible&&<li key={child?.name + index.toString()}><Link to={child.link}>{child.name}</Link></li>
                                        ))}
                                    </ul>
                                ) : null}
                           </li>
                       )
                    })
                }
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
    
                        {
                            this.props.navItems.map(this.menuStack)
                        }

                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter((SidebarContent));
