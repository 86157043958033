
import React, { useEffect, useMemo, useState } from 'react';
import { atom, selector,useRecoilState,useRecoilValue } from "recoil";
import { createDataRequest, updateDataRequest } from "../../../api/controller/ContactController";
import { getDataListRequest } from '../../../api/controller/GroupController'; // CHANGE (controller name)
 

 

export const contactListState = atom({
    key: 'contactListState',
    default: [],
})

export const groupListState = atom({
    key: 'groupListState',
    default: [],
})

export const modalConfigDefault = { size: "md", wizard: false }

export const filterConfigDefault = {
    filter: true,
    label: 'Group',
    type: 'select', // date, dateRange & select only
    default: '', // keep same as filterValue
    filterValue: '', // String date "yyyy-mm-dd" for date | Array of 2 dates for dateRange | Object as shown for Select { label: "Label", value: 0 }
    list: [], // only for select -> Array should have Objects as shown { label: "Label", value: 0 }
}

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

 
export const contactDataStructureState = selector({
    key: 'contactDataStructureState',
    get: ({ get }) => {       
        return (
        [{ 
            label: "Group Id",
            type: "select",
            order: { table: 0, form: 1 },
            list : get(groupListState),
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: ['same_as:create.0'],
          },{ 
            label: "group.name",
            type: "text",
            order: { table: 2, form:2 },
            create: [{ enabled: false, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          },{ 
            label: "Contact",
            type: "text",
            order: { table: 3, form: 3 },
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
          }]
        )
    }
})

 








