
import http from "../core/http";
const group = 'template';

export const getDataListRequest =  async (data) => {
    return await http.post(`${group}/get`, data).then(res => res.data).catch(http.defaultCatch);
}

export const createDataRequest =  async (data) => {
    return await http.post(`${group}/create`, data).then(res => res.data).catch(http.defaultCatch);
}

export const updateDataRequest =  async (data) => {
    return await http.post(`${group}/update`, data).then(res => res.data).catch(http.defaultCatch);
}

export const deleteDataRequest =  async (data) => {
    return await http.post(`${group}/delete`, data).then(res => res.data).catch(http.defaultCatch);
}

// export const deleteUploadRequest =  async (data) => {
//     return await http.post(`${group}/deleteUpload`, data).then(res => res.data).catch(http.defaultCatch);
// }

// export const deleteContactRequest =  async (data) => {
//     return await http.post(`${group}/deleteContact`, data).then(res => res.data).catch(http.defaultCatch);
// }


// export const getCountryListRequest =  async (data) => {
//     return await http.post(`countries/get`, data).then(res => res.data).catch(http.defaultCatch);
// }
// export const getCityListRequest =  async (data) => {
//     return await http.post(`cities/get`, data).then(res => res.data).catch(http.defaultCatch);
// }
// export const getHobbiesListRequest =  async (data) => {
//     return await http.post(`hobbies/get`, data).then(res => res.data).catch(http.defaultCatch);
// }