import React, { useEffect, useState } from 'react';

import { Alert, Card, CardBody, Col, Container, Row,  Form, FormGroup, Label,  Input } from "reactstrap";
import { Link, withRouter } from 'react-router-dom';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"


// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-light.svg";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loginErrorState } from '../../state/ErrorState';
import { loginRequest } from '../../api/controller/AuthController'
import { passwordResetHashState } from '../../api/state/AuthState';

import logodark from "../../assets/images/logo-dark-full.svg"
import logolight from "../../assets/images/logo-light.png"




const CarouselPage = () => {
    return (
      <React.Fragment>
        <Col xl={9}>
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
              <div className="d-flex h-100 flex-column">
                <div className="py-5 px-4 mt-auto">
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <div className="text-center">
                        {/* <h4 className="mb-1">
                          <i className="bx bxs-quote-alt-left text-primary h1 align-middle mr-3"></i>
                          <span className="text-primary">5k</span>+ Satisfied
                          clients
                        </h4> */}
                        {/* <Carousel showThumbs={false} className="slider_css">
                          <div>
                            <div className="item">
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  " Fantastic theme with a ton of options. If you
                                  just want the HTML to integrate with your
                                  project, then this is the package. You can find
                                  the files in the 'dist' folder...no need to
                                  install git and all the other stuff the
                                  documentation talks about. "
                                </p>
  
                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Abs1981
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Skote User
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="item">
                              <div className="py-3">
                                <p className="font-size-16 mb-4">
                                  "If Every Vendor on Envato are as supportive as
                                  Themesbrand, Development with be a nice
                                  experience. You guys are Wonderful. Keep us the
                                  good work. "
                                </p>
  
                                <div>
                                  <h4 className="font-size-16 text-primary">
                                    Abs1981
                                  </h4>
                                  <p className="font-size-14 mb-0">
                                    - Skote User
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Carousel> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    )
  }



const Login = ({history,handleAuthResponse}) =>  {
    const [error, setError] = useRecoilState(loginErrorState)
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('authUser')))
    const [rememberMe, setRememberMe] = useState(false)
    const setPasswordResetHash = useSetRecoilState(passwordResetHashState)

    // handleValidSubmit
    const handleValidSubmit = async (event, values) => {
        setSubmitDisabled(true)
        const response = await loginRequest({email: user ? user.email : values.email, password: values.password})
        if(response.decodedToken){
            handleAuthResponse(response, response.encodedToken, rememberMe)
        } else if(response.scp){
            localStorage.removeItem('authToken')
            setPasswordResetHash(response.hash)
            history.push('/change-password');
        } else if (response.message){
            setError(response.message)
            setSubmitDisabled(false)
        } else {
            setError("Invalid Credentials!")
            setSubmitDisabled(false)
        }
    }

    useEffect(() => {
        setError("");
    }, [setError])

    return (
        <React.Fragment>
            <div>
            <Container fluid className="p-0">
                <Row className="no-gutters">
                    <CarouselPage />

                    <Col xl={3}>
                    <div className="auth-full-page-content p-md-5 p-4">
                        <div className="w-100">
                        <div className="d-flex flex-column h-100">
                            <div className="my-auto">
                            <div  className="mb-4 mb-md-5">
                            <img
                                src={logodark}
                                alt=""
                                height="60"
                                className="auth-logo-dark"
                                />
                            </div>

                            <div className="mt-4">
                                <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>

                                {error ? <Alert color="danger">{error}</Alert> : null}

                                {user ? (
                                    <div className="user-thumb text-center mb-4">
                                        <img src={user.avatar} className="rounded-circle img-thumbnail avatar-md" alt="thumbnail"/>
                                        <h5 className="font-size-15 mt-3">{user.name}</h5>
                                    </div>
                                ) : (
                                    <div className="form-group">
                                        <AvField name="email" label="Email" value="" className="form-control" placeholder="Enter email" type="email" required />
                                    </div>
                                )}


                                <div className="form-group">
                                    <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                </div>

                                {!user ? (
                                    <div className="custom-control custom-checkbox" onClick={()=>setRememberMe(!rememberMe)}>
                                        <input type="checkbox" className="custom-control-input" onChange={()=>false} checked={rememberMe} />
                                        <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                    </div>
                                ) : null}


                                <div className="mt-4">
                                    <button disabled={submitDisabled} className="btn btn-primary btn-block waves-effect waves-light" type="submit">{user ? "Unlock" : "Login"}</button>
                                </div>

                                <div className="mt-4 text-center">
                                    <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                </div>

                                 
                                </AvForm>
                            </div>
                            </div>
                            {user ? (
                                <div className="mt-5 text-center">
                                    <p>Not you? return to <Link onClick={()=>{localStorage.removeItem('authUser');setUser(null)}} to="/" className="font-weight-medium text-primary"> Sign In </Link> </p>
                                </div>
                            ) : null}
                            <div className="mt-4 mt-md-5 text-center">
                            <p className="mb-0">
                                © {new Date().getFullYear()} Text Maldives {""}.<br/> 
                                Made with <i className="mdi mdi-heart text-danger"></i> in Maldives.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </Col>
                </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Login);
