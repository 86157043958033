
import { atom, selector } from "recoil";
import { createDataRequest, deleteContactRequest, deleteUploadRequest, getCityListRequest, updateDataRequest } from "../../../api/controller/ProfileController";
/* INCLUDE ONLY IF COLUMN LEVEL PERMISSION REQUIRED
import { HasPermission } from "../../helpers/Custom/HasPermission";
import { pageState } from "../../state/GlobalState";
import { permissionState } from "./AuthState";
 INCLUDE ONLY IF COLUMN LEVEL PERMISSION REQUIRED */

export const profileListState = atom({
    key: 'profileListState',
    default: [],
})

export const countryListState = atom({
    key: 'countryListState',
    default: [],
})

export const hobbyListState = atom({
    key: 'hobbyListState',
    default: [],
})


// PAGE CONFIGURATIONS BELOW

export const modalConfigDefault = { size: "xl", wizard: true }

export const filterConfigDefault = {
    filter: false,
    // label: 'Date',
    // type: 'date', // date, dateRange & select only
    // default: moment().format("YYYY-MM-DD"), // keep same as filterValue
    // filterValue: moment().format("YYYY-MM-DD"), // String date "yyyy-mm-dd" for date | Array of 2 dates for dateRange | Object as shown for Select { label: "Label", value: 0 }
    // list: [], // only for select -> Array should have Objects as shown { label: "Label", value: 0 }
}

// Add more form modes for variations of create, update and views with less or more columns (Can be used for permission separation as well)
export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const profileDataStructureState = selector({
    key: 'profileDataStructureState',
    get: ({ get }) => (
        [{
            label: "Id Number",
            type: "number",
            order: { table: 1, form: 1 },
            section: { id: 1, label: "Basic Info"},
            create: [{ enabled: true, size: 3 }],
            update: [ 'same_as:create.0' ], view: [ 'same_as:create.0' ],
            list: [],
            children: [],
        },{ 
            label: "Fullname",
            field: "fullname",
            type: "text",
            order: { table: 2, form: 2 },
            section: { id: 1, label: "Basic Info"},
            create: [{ enabled: true, size: 3 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{ 
            label: "Email",
            field: "email",
            type: "email",
            order: { table: 3, form: 3 },
            section: { id: 1, label: "Basic Info"},
            create: [{ enabled: true, size: 3 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{ 
            label: "Password",
            field: "password",
            type: "text",
            order: { form: 4 },
            section: { id: 1, label: "Basic Info"},
            create: [{ enabled: true, size: 3, default: 'welcome@123' }],
        },{ 
            label: "Contact Numbers",
            field: "contact_numbers",
            type: "repeater",
            order: { form: 5 },
            section: { id: 1, label: "Basic Info"},
            deleteFn: deleteContactRequest,
            create: [{ enabled: true, size: 12, deleteBtnSize: 2, repeatLabel: false }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            children: [
              {
                label: "Number",
                field: "number",
                type: "mask",
                order: { form: 1 },
                create: [{ enabled: true, size: 4, mask: "(\\960)-999-9999" }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
              },
              {
                label: "Country",
                field: "city.country",
                subFields: { label: "name", value: "id" },
                type: "select",
                list: get(countryListState),
                order: { form: 1 },
                create: [{ enabled: true, size: 3 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
                child: {
                  name: 'City',
                  fn: getCityListRequest
                }
              },
              {
                label: "City",
                field: "city",
                subFields: { label: "name", value: "id" },
                type: "select",
                order: { form: 1 },
                create: [{ enabled: true, size: 3, validations: 'disabled_if:Country,null' }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
              },
            ]
        },{
            label: "Gender",
            field: "gender",
            type: "radio",
            order: { form: 5 },
            section: { id: 2, label: "Advanced Info"},
            create: [{ enabled: true, size: 3 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: [
              { label: 'Male', value: 'Male' },
              { label: 'Female', value: 'Female' },
            ],
        },{
            label: "Are you over 18?",
            name: "IsOver18",
            field: "over_18",
            type: "switch",
            order: { form: 6 },
            section: { id: 2, label: "Advanced Info"},
            create: [{ enabled: true, size: 2 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: ['Yes', 'No'],
        },{
            label: "Agreed To Tnc",
            field: "agreed_tnc",
            type: "checkbox",
            order: { form: 7 },
            section: { id: 2, label: "Advanced Info"},
            create: [{ enabled: true, size: 2, validations: 'required' }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{
            label: "Country",
            field: "city.country",
            subFields: { label: "name", value: "id" },
            type: "select",
            order: { form: 8, table: 4 },
            section: { id: 2, label: "Advanced Info"},
            create: [{ enabled: true, size: 4, default: 4 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: get(countryListState),
            child: {
              name: 'City', // has to be PN here
              fn: getCityListRequest
            },
        },{
            label: "City",
            field: "city",
            subFields: { label: "name", value: "id" },
            type: "select",
            order: { form: 9, table:5 },
            section: { id: 2, label: "Advanced Info"},
            create: [{ enabled: true, size: 4, validations: 'disabled_if:Country,null' }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{
            label: "Hobbies",
            field: "hobbies",
            subFields: { label: "name", value: "id" },
            type: "multiselect",
            order: { form: 10 },
            section: { id: 2, label: "Advanced Info"},
            create: [{ enabled: true, size: 4, default:[5,6,7] }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
            list: get(hobbyListState),
        },{
            label: "Phone Number",
            field: "phone_number",
            type: "mask",
            order: { form: 11 },
            section: { id: 3, label: "Dates"},
            create: [{ enabled: true, size: 3, mask: "(\\960)-999-9999" }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{
            label: "Date Of Birth",
            field: "dob",
            type: "datepicker",
            order: { form: 12 },
            section: { id: 3, label: "Dates"},
            create: [{ enabled: true, size: 4, options: { altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d", maxDate: 'today' } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{
            label: "Short Bio",
            field: "short_bio",
            type: "textarea",
            order: { form: 13 },
            section: { id: 4, label: "Large Texts"},
            create: [{ enabled: true, size: 12, attributes: { maxLength: "225", rows: "3" } }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{
            label: "Long Bio",
            field: "long_bio",
            type: "wysiwyg",
            order: { form: 14 },
            section: { id: 4, label: "Large Texts"},
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{
            label: "Profile Picture",
            field: "profile_picture",
            type: "file",
            order: { form: 15 },
            section: { id: 5, label: "Files"},
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        },{
            label: "Uploads",
            field: "uploads",
            subFields: { name: "name", path: "path", size: "size" },
            deleteFn: deleteUploadRequest,
            type: "dropzone",
            order: { form: 16 },
            section: { id: 5, label: "Files"},
            create: [{ enabled: true, size: 12 }], update: ['same_as:create.0'], view: [ 'same_as:create.0' ],
        }]
        /* INCLUDE ONLY IF COLUMN LEVEL PERMISSION REQUIRED
        .filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
        INCLUDE ONLY IF COLUMN LEVEL PERMISSION REQUIRED */
    )
})